import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Box } from '@mui/material';

import L from "leaflet";

const customMarker = new L.icon({
  // iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  iconUrl: "/images/marker.png",
  iconSize: [25, 41],
  iconAnchor: [13, 0]
});

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // const popupRef = useRef();

  return (
    <Box height={450}>
      <MapContainer 
        center={[51.505, -0.09]} 
        zoom={13} 
        scrollWheelZoom={false}
        style={{ width: "100%", height: 450, zIndex:0 }}
      >
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri"
        />
        
        {/* <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> */}
        
        <Marker 
          position={[51.505, -0.09]} 
          icon={customMarker}
        >
          <Popup>
            Kurai Medial Aid <br /> The Next Level in Medical Insurance
          </Popup>
        </Marker>
      </MapContainer>
    </Box>
  );
};