import React from "react";
import { Box, Button, Container, Grid, Hidden, Stack, Typography } from "@mui/material";
import { useTheme } from '@mui/material';

const benefits = [
  "Lorem ipsum dolor sit amet",
  "Lorem ipsum dolor sit amet",
  "Lorem ipsum dolor sit amet",
  "Lorem ipsum dolor sit amet",
  "Lorem ipsum dolor sit amet"
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { palette } = useTheme();

  return (
    <Container>
      
      <Box mt={10} />
      
      <Grid container>
        <Grid item xs={12} md={4}>
          <Stack
            justifyContent="space-evenly"
            height="100%"
          >
            <Box>
              <Typography variant="h4">
                Health is the most important thing you have in life!
              </Typography>

              <br />
              
              <Typography variant="p">
                Welcome to <em>Kurai Medical</em>, we are the preferred choice for, 
                consectetur adipiscing elit. Curabitur eget leo at velit imperdiet 
                varius. In eu ipsum vitae velit congue iaculis vitae at risus 
              </Typography>
              
              <Typography variant="p">
                Donec velit. Mauris massa. Vestibulum non nulla. Nam suscipit arcu 
                nec elit.
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4}
          // style={{border:"1px red solid"}}
        >
          <Hidden mdDown>
            <Box 
              display="flex" 
              justifyContent="center" 
              alignItems="flex-end" 
              height="100%"
            >
              <img 
                alt="Kurai Medical Professionals" 
                src="/images/doctor.jpeg" //
                // width={350}
              />
            </Box>
          </Hidden>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            mt={3}
            border={`1px ${palette.secondary.main} solid`}
          >
            <Box 
              backgroundColor={palette.secondary.main}
              color="white"
              p={3}
              textAlign="center"
            >
              <Typography variant="h4">
                Our Advantage
              </Typography>
            </Box>

            <Box p={3}>
              <Box mb={2}>
                {benefits.map((item, i) => (
                  <Box key={i} display="flex" alignItems="center" mb={2}>
                    <img alt="-" src="/images/check.svg" width={40} />
                    <Typography variant="list">{item}</Typography>
                  </Box>
                ))}
              </Box>

              <Button 
                variant="contained"
                component="a"
                href="/#"
                fullWidth
                color="primary"
                sx={{padding:1.8, border:"1px white solid"}}
              >
                Claim Form
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
        
    </Container>
  );
};