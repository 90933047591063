import React from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography
} from '@mui/material';

// import HomeIcon from '@mui/icons-material/HomeRounded';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/CloseRounded';

// import { StateContext } from 'src/contexts';

import NavItem from './NavItem';

import { ReactComponent as About } from "src/svg/nav/about.svg";
import { ReactComponent as Package } from "src/svg/nav/package.svg";
import { ReactComponent as Medical } from "src/svg/nav/medical.svg";
import { ReactComponent as Discover } from "src/svg/nav/discover.svg";
import { ReactComponent as Faq } from "src/svg/nav/faq.svg";
import { ReactComponent as Contact } from "src/svg/nav/contact.svg";

const menu = [
  { name: "HOME", url: "/", icon: <About /> },
  { name: "ABOUT", url: "/about", icon: <About /> },
  { name: "PACKAGES", url: "/packages", icon: <Package /> },
  { name: "SERVICES", url: "/services", icon: <Medical /> },
  { name: "DISCOVER", url: "/discover", icon: <Discover /> },
  { name: "FAQ", url: "/faq", icon: <Faq /> },
  { name: "CONTACT", url: "/contact", icon: <Contact /> },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default ({onMobileClose}) => {
  // const {backgroundRGBA } = useContext(StateContext);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      // justifyContent={"space-evenly"}
      backgroundColor={({palette}) => palette.primary.main}
      // sx={{backgroundColor: backgroundColor}}
      sx={{
        backgroundImage: "url(/images/world-map.png)", 
        backgroundRepeat: "no-repeat", 
        backgroundPositionX: "center",
        backgroundPositionY: 80
      }}
    >
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        // mb={3}
        p="0 16px"
        // width="100%"
        backgroundColor={({palette}) => palette.background.dark}
      >
        <img src="/images/logo-full-white.svg" alt="Kurai Medical" width={200}/>
        <IconButton
          size="small"
          // color="white"
          onClick={() => onMobileClose()}
          sx={{color: "white"}}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box p="0 16px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={3}
          pl={2}
          backgroundColor="grey"
        >
          <Typography mb={0} variant="h4">Navigation</Typography>
          <IconButton>
            <PersonIcon color="secondary" />
          </IconButton>
        </Box>

        <Divider sx={{margin:"10px 0", backgroundColor: "#eee"}} />
        
        <Box pl={1}>
          <Box 
            // display="flex" 
            // flexWrap="wrap" 
            width="100%"
          >
            {menu.map(({name, icon, url}, i) => (
              <NavItem
                // ref={e => (menuNodes[i] = e)}
                // ref={(e) => handleMenuNodes(e)}
                // ref={(e) => console.log(i)}
                key={i}
                href={url}
                title={name}
                icon={icon}
                // flex="50%"
                // count={title === 'Notifications'}
              />
            ))}
            
            {/* <NavItem href="/register" title="REGISTER" />
            <NavItem href="/contact" title="CONTACT US" /> */}
          </Box>
        </Box>

        <Box
          pt={5}
          display="flex"
          justifyContent={"center"}
        >
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{
              borderRadius: 0,
              color: "white",
              padding: 2
            }}
          >
            Get started
          </Button>
        </Box>
      </Box>
    </Box>
  );
};