import React from "react";
import PropTypes from 'prop-types';
import { Reveal, Tween } from "react-gsap";

const CascadeDown = ({ children, repeat, divStyle }) => {
  return (
  <Reveal repeat={repeat} trigger={<div style={divStyle} />}>
    <Tween
      from={{y:300, opacity:0}}
      to={{
        y: 0, 
        opacity: 1, 
        duration: 2,
        stagger: 0.2,
        ease: "elastic.out(0.2, 0.1)",
      }}
    >
      {children}
    </Tween>
  </Reveal>
  );
  
};

CascadeDown.propTypes = {
  children: PropTypes.node.isRequired,
  repeat: PropTypes.bool,
  divStyle: PropTypes.object
};

CascadeDown.defaultProps = {
  repeat: true,
  divStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    // border: "1px orange solid"
  }
};

export default CascadeDown;