import React from "react";
import { Box, Container, Grid, IconButton, Stack, Typography } from "@mui/material";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const team = [
  {
    image: "/images/person-1.jpg",
    name: "Dr Arush Janji",
    title: "CEO, Founder",
    description: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat"
  },
  {
    image: "/images/person-2.jpg",
    name: "Prof Abigail Haggle",
    title: "Operations",
    description: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat"
  },
  {
    image: "/images/person-3.jpg",
    name: "Mr Tendai Goremusandu",
    title: "Finance",
    description: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat"
  },
  {
    image: "/images/person-4.jpg",
    name: "Mr Timothy James",
    title: "ICT",
    description: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat"
  },
  {
    image: "/images/person-5.jpg",
    name: "Ms Sarah Hove",
    title: "Marketing",
    description: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat"
  },
  {
    image: "/images/person-6.jpg",
    name: "Mr Tony Kurima",
    title: "Administration",
    description: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat"
  },
  {
    image: "/images/person-7.jpg",
    name: "Mr James Masango",
    title: "Accounting",
    description: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat"
  },
  {
    image: "/images/person-8.jpg",
    name: "Mr Gregory Konnel",
    title: "Legal",
    description: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat"
  }
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Box
      // backgroundColor="#364f6b"
      marginTop={5}
      // padding={20}
      // color="white"
      textAlign="center"
    >
      <Container>
        <Box p={5}>
          <Typography variant="h1" color="primary">Meet the Team</Typography>
          <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
        </Box>
        
        <Grid container spacing={3}>
          {team.map((s, i) => (
            <Grid key={i} item xs={6} md={3} style={styles.item}>
              <Stack>
                <img alt={s.name} src={s.image} width="100%" />
                <Typography variant="h5" mb={1} mt={2}>{s.name}</Typography>
                <Typography variant="body2" mb={1}>{s.title}</Typography>
                <Typography variant="body2">{s.description}</Typography>
                
                <Box
                  mt={1}
                  display="flex"
                  justifyContent={"space-evenly"}
                  // p="10px 0"
                  backgroundColor={({palette}) => palette.background.light}
                >
                  <IconButton>
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton>
                    <TwitterIcon />
                  </IconButton>
                  <IconButton>
                    <FacebookIcon />
                  </IconButton>
                  
                  {/* <InstagramIcon fontSize="large" /> */}
                </Box>
              </Stack>
            </Grid>))}
        </Grid>
        

      </Container>
      
      {/* <Box
        p={10}
        backgroundColor="maroon"
      >
        <Container>
          <Typography variant="h3">
            As a #ProudlySouthAfrican company, we are committed 
            to an integrated Affirmative Action Policy. 
          </Typography>
        </Container>
      </Box> */}
    </Box>
  );
};

const styles = {
  item: {
    // flex: "0 0 0px",
    paddingBottom: 50,
    // border: "1px white solid",
    textAlign: "center",
    // borderRadius: "50%",
  }
};