import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import HomeIcon from '@mui/icons-material/HomeRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIosRounded';

import { StateContext } from 'src/contexts';


// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { services } = React.useContext(StateContext);

  return (
    <Box>
      <nav aria-label="services">
        <Stack display="flex">
          <Box 
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            component="a"
            href="/services"
            mt={2}
            mb={1}
            p={2}
            color="white"
            backgroundColor={({palette}) => palette.primary.main}
            sx={{textDecoration: "none"}}
          >
            <Typography mb={0}>Services</Typography>
            <HomeIcon />
          </Box>

          {services.map(({title, url}, id) => (
            <Box 
              key={id}
              component="a"
              display="flex"
              justifyContent="space-between"
              href={url}
              p={2}
              // mr={1}
              mb={1}
              // flex="1 1 0"
              border="1px lightgray solid"
              sx={{
                textDecoration: "none",
                ":hover": {
                  boxShadow: "0px 0px 25px 3px rgba(0, 0, 0, 0.5)",
                  // transition: "transform 0.3s"
                  transition: "300ms ease, 300ms ease transform",
                  transform: `translateY(-1px)`,
                }
              }}
            >
              <Typography 
                mb={0} 
                variant="body2"
                color="primary"
              >
                {title}
              </Typography>
              <ArrowForwardIcon fontSize="small" color="secondary" />
            </Box>
          ))}          
        </Stack>
      </nav>
    </Box>
  );
};