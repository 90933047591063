import React from 'react';
import { Box } from '@mui/material';
import { Page } from 'src/components';

import Banner from "./Banner";
import Intro from './Intro';
import Questions from './Questions';
import RequestForm from './RequestForm';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Page 
      title="Frequently Asked Questions"
    >
      <Banner />
      <Intro />
      <Questions />
      <Box p="50px 0" />
      <RequestForm />
    </Page>
  );
}
