import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography } from '@mui/material';

const NavItem = React.forwardRef(({ className, href, icon, title, count, ...rest }, ref) => (
  <Box
    ref={ref}
    className={clsx(className)}
    // disableGutters
    style={styles.item}
    {...rest}
  >
    <Button
      component="a"
      style={styles.button}
      href={href}
      // activeClassName={classes.active}
      // component={RouterLink}
      sx={({palette}) => ({
        // backgroundColor: palette.secondary.main
      })}
    >
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
        // border="1px white solid"
        width="100%"
      >
        <Box width={3} height={30} mr={1} sx={({palette}) => ({
          backgroundColor: palette.secondary.main
        })} />

          {/* {icon}
        </Box> */}
        <Typography
          mb={0}
          // fontWeight={"bold"}
          letterSpacing={1}
          color="secondary"
          style={styles.title}
        >
          {title}
        </Typography>

      </Stack>
    </Button>
  </Box>
));

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string
};

export default NavItem;

const styles = {
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    width: "calc(100%-50px)",
  },
  button: {
    // marginBottom: 10,
    // marginRight: 10,
    // color: theme.palette.text.secondary,
    // fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    borderRadius: 0
  },
  icon: {
    // marginRight: theme.spacing(1)
  },
  title: {
    // marginRight: 'auto'
    // color: "white",
    fontSize: 14
  },
  active: {
    color: "#ffffff", //theme.palette.primary.main,
    '& $title': {
      // fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      // color: theme.palette.primary.main
    }
  }
};