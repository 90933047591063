import { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const calcHeight = window.innerHeight - 110;

const Section = forwardRef(({children,...rest}, ref) => {
  const [defaultHeight, setDefaultHeight] = useState(rest.height ?? calcHeight);

  useEffect(() => {
    const handleResize = window.addEventListener("resize", () => {
      setDefaultHeight(window.innerHeight - 110);
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  });

  return (
    <Box
      ref={ref}
      component={"section"}
      display={rest.display}
      flexDirection={rest.flexDirection}
      justifyContent={rest.justifyContent}
      alignItems={rest.alignItems}
      textAlign={rest.textAlign}
      height={defaultHeight}
      overflow={rest.overflow}
      color={rest.color}
      border={rest.border}
      p={rest.p || rest.padding}
      sx={rest.sx}
      {...rest}
    >
      {children}
    </Box>
)});

Section.propTypes = {
  children: PropTypes.node.isRequired,
  // id: PropTypes.string.isRequired,
  height: PropTypes.any,
  display: PropTypes.string,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  textAlign: PropTypes.string,
  overflow: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.any,
  p: PropTypes.any,
  sx: PropTypes.object
};

Section.defaultProps = {
  height: calcHeight,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // alignItems: "center",
  // textAlign: "center",
  overflow: "hidden",
  // color: "white",
  p: "60px 0",
  // border: {
  //   xs: "1px orange solid",
  //   ss: "1px pink solid",
  //   sm: "1px green solid",
  //   md: "1px aqua solid",
  //   lg: "1px blue solid", 
  //   xl: "1px purple solid"
  // }
}

export default Section;
