import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography';

// import preloader from '../assets/images/preloader.gif';

const styles = {
  loader: { 
    display: 'flex',
    flex: 1,
    flexDirection:'column',
    padding: '20%',
    alignItems: 'center',
  }
};

export const Loader = (props) => {
  return ( 
    <div style={styles.loader}>
      <CircularProgress disableShrink />
      
      <Typography variant="h3">
        {props.message}
      </Typography>
    </div>
  );
}

export const SmallLoader = (props) => {
  return ( 
    props.show ? <CircularProgress /> : <div></div>
  );
}

Loader.propTypes = {
  message: PropTypes.string
};

export default Loader;