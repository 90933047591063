import React, { useState } from 'react';
import * as yup from "yup";
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // const [label, setLabel] = useState("");
  
  const [values, setValues] = useState({
    name: "",
    surname: "",
    contact: "",
    email: ""
  });

  let schema = yup.object().shape({
    name: yup.string().required(),
    surname: yup.string().required(),
    mobile: yup.string().required(),
    email: yup.string().email().required(),
  });


  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setValues({...values, [key]: value});
    
    // if (key === "mobile") {
    //   setLabel((value === "Mobile") ? "Enter your mobile number" : "Enter your email address");
    // }
  };

  const handleSubmit = async (values) => {
    // schema.isValid(values).then(valid => {
    //   console.log(valid);
    // }).catch(errors => {
    //   console.log(errors);
    // });

    const e = await schema.validate(values, {abortEarly:false}).catch((e) => {
      console.log(e.name, e.errors);
    });

    console.log(e);

  };

  return (
    <Container>
      <Box
        mt={10}
        backgroundColor={({palette}) => palette.primary.main}
        sx={{background: "url(/images/request-call-bg.jpg) center no-repeat", backgroundSize:"cover"}}
      >
        <Grid container 
          // padding={{lg:"50px 300px"}}
          // display="flex"
          // backgroundColor="red"
          // justifyContent="center" 
          // sx={{border:"1px red solid"}}
          height={{xs:"auto", md:350}}
        >
          <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="center" height={200}>
              <Box 
                position={{xs:"relative", md:"relative"}}
                top={{xs:-50, md:-110}}
                width={{ xs:180, md:350}}
              >
                <img alt="Request a call" src="/images/request-call.png" width="100%"/>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box 
              p={2}
              height="100%"
              display="flex"
              alignItems="center"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    mb={2}
                    color="white"
                    textAlign="center"
                  >
                    <Typography variant="h6">REQUEST A CALL</Typography>
                    <Typography variant="h3">We Call You Back</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    focused
                    name="name"
                    label="Full name"
                    placeholder="Enter your full name"
                    margin="none"
                    variant="standard"
                    color="warning"
                    value={values.name}
                    onChange={handleChange}
                    sx={{ input: { color: "white" } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    focused
                    name="mobile"
                    label="Mobile number"
                    margin="none"
                    variant="standard"
                    color="warning"
                    type="number"
                    value={values.mobile}
                    onChange={handleChange}
                    sx={{ input: { color: "white" } }}
                  />
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Button
                    fullWidth
                    variant={"outlined"}
                    onClick={() => handleSubmit(values)}
                    sx={{border: "2px white solid", borderRadius:0, color: "#fff", padding:2}}
                  > 
                    submit
                  </Button>
                  {/* <Box mt={2}>
                    <Alert>We will do our best to respond to you as soon as possible</Alert>
                    <Alert>All calls will be made within business hours</Alert>
                  </Box> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>

          
          
        </Grid>
      </Box>
    </Container>
  )
}
