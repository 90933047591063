import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Box, CircularProgress, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { StateContext } from 'src/contexts';
import { LargeButton, TextField } from 'src/components';


// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { user } = useContext(StateContext);
  const [success, setSuccess] = useState(false);
  const [busy, setBusy] = useState(false);

  const data = [
    { label: "Name:", value: `${user.name} ${user.surname}` },
    { label: "Email:", value: user.username },
    { label: "Mobile:", value: user.profile.mobile },
  ];

  return (
    <Stack>
      <Typography variant="h1">Congratulations</Typography>
      <Typography variant="h5" mb={2}>Your registration was successful!</Typography>
      
      <Grid container mb={1} mt={{xs:2, md:4, xl:6}}>
        <Grid item xs={12} md={6}>
          <Box display="flex" height="100%">
            <Box>
              <img alt="package" src="/images/package-2.jpg" />
            </Box>
            <Stack justifyContent={"center"}>
              <Typography variant="h5">Standard (Package)</Typography>
              <Typography>$23.45</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack justifyContent={"center"} height="100%">
            <Box 
              p={3} 
              backgroundColor={({palette}) => palette.background.light}
              sx={{borderLeft: "8px teal solid"}}
            >
              {data.map(({label, value}, i) => (
                <Typography key={i}>
                  <span style={{width:80, display:"inline-block"}}>{label}</span> {value}
                </Typography>
              ))}

            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Box p={{xs:0, md:0, xl:5}} />
      
      <Typography>
        To complete the process you need to pay your subscription
      </Typography>

      <Box>
        <Formik
          initialValues={{
            method: "",
            mobile: "",
            serverValidationErrors: false
          }}
          validationSchema={
            Yup.object().shape({
              mobile: Yup.string().max(255).required("Your mobile number is required"),
              method: Yup.string().required("Select a payment method"),
            })
          }
          onSubmit={async (values, actions) => {
            // data 
            await handleSubmit(values, actions);

            // dummy 
            setBusy(true);
            setSuccess(false);

            await setTimeout(() => {
              setBusy(false);
              setSuccess(true);
            }, 2000);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            // setValues,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth={true}
                    name="method"
                    label="Payment Method"
                    margin="none"
                    type="text"
                    variant="outlined"
                    value={values.method}
                    onChange={handleChange}
                    error={Boolean(errors.method)}
                    helperText={Boolean(errors.method) && errors.method}
                  >
                    {["EcoCash", "OneMoney"].map((p, i) => <MenuItem key={i} value={p}>{p}</MenuItem>)}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    name="mobile"
                    label="Mobile Number"
                    placeholder="The mobile number paying the subscription"
                    margin="none"
                    type="text"
                    variant="outlined"
                    value={values.mobile}
                    onChange={handleChange}
                    error={Boolean(errors.mobile)}
                    helperText={Boolean(errors.mobile) && errors.mobile}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <LargeButton
                    // sx={{padding:4}} 
                    color="primary"
                    // disabled={isSubmitting}
                    // endIcon={isSubmitting ? <CircularProgress color="secondary" size={20} thickness={8} /> : null}
                    
                    disabled={busy}
                    endIcon={busy ? <CircularProgress color="secondary" size={20} thickness={8} /> : null}

                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {/* {isSubmitting ? "Processing..." : "Make Payment"} */}
                    {busy ? "Processing..." : "Make Payment"}
                  </LargeButton>
                </Grid>
                
                <Grid item xs={12}>
                  {success && ( 
                  <Alert severity="info">
                    {`TODO: 1) Validate payment 2) Record transaction`}
                  </Alert>)}
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
      
      <Box p={4} />

    </Stack>
  );
};


function handleSubmit(values, actions) 
{  
  // axios.post(`${urls.baseURL}/feedback`, values).then((response) => {
  //   const result = response.data;
    
  //   if (result.success) {  
  //     actions.resetForm({"serverValidationErrors": false});
  //     actions.setStatus("Your feedback has been submitted to us. Thank you for your time!");
  //   }
  //   else {
  //     actions.setFieldValue("serverValidationErrors", true);
  //     actions.setStatus("You have invalid entries");

  //     Object.keys(result.data).map(field => actions.setFieldError(field, result.data[field][0]));
  //   }
  // })
  // .catch(error => {
  //   actions.setFieldValue("serverValidationErrors", true);
  //   actions.setStatus(`Your ${values.type} cannot be accepted at the moment. Try again in a few minutes`);
  // })
  // .finally(() => {
  //   actions.setSubmitting(false); 
  // });
}