import PropTypes from "prop-types";

import "./index.css";

const CarouselItem = ({children, width, height, background }) => {
  return (
    <div 
      className="carousel-item"
      style={{
        width: width,
        height: height,
        // backgroundImage: `url(${background})`, 
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundColor: "gray"
      }}
    >
      {children}
    </div>
  );
};

CarouselItem.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.any,
  height: PropTypes.any,
  background: PropTypes.string
};

CarouselItem.defaultProps = {
  width: "100%",
  // height: (window.innerHeight - 100)
  height: 600
};

export default CarouselItem;