import React from 'react';
// import { useTheme } from '@mui/material';

import { Page } from 'src/components';
// import { DispatchContext } from 'src/contexts';

import Carousel from './sections/Carousel';
import About from './sections/About';
import Discover from './sections/Discover';
import Packages from './sections/Packages';
import Services from './sections/Services';
// import Advert1 from './sections/Advert1';
import RequestForm from './sections/RequestForm';
import Brands from './sections/Brands';
// import Intro from "./Intro";
// import Section1 from './Section1';
// import Section2 from './Section2';
// import Section4 from './Section4';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // const { palette } = useTheme();
  // const dispatch = useContext(DispatchContext);
  
  // useEffect(() => {
  //   dispatch({type: "set-menu-color", payload: palette.colors.home});
  // }, [dispatch, palette]);

  return (
    <Page
      title="Intro" 
      // color={palette.colors.home}
    >
      <Carousel />
      <About />
      <Discover />
      <Packages />
      <Services />
      <Brands />
      {/* <Advert1 /> */}
      <RequestForm />
      {/* <div style={{height:600, backgroundColor: "gray"}} /> */}

    </Page>
  );
}
