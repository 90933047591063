import React from 'react';

import { Typography } from "@mui/material";
import { Banner, Page } from 'src/components';

import About from './About';
import List from './List';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Page
      title="About"
    >
      <Banner
        title={<Typography variant="h1">Packages</Typography>} 
      />
      <About />
      {/* <Services /> */}
      <List />
    </Page>
  );

}

