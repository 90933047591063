import React, { useContext } from 'react';
import { Box, Button, Container, Hidden, IconButton } from "@mui/material";
import { StateContext } from 'src/contexts';
import { Link } from 'src/components';

import PersonIcon from '@mui/icons-material/Person';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({height}) => {
  const { menu } = useContext(StateContext);
  // const { backgroundRGBA } = useContext(StateContext);

  return (
    <Container sx={{zIndex:1}}>
      <Box pt={{xs:7.1, sm:0}} />
        
      <Box 
        component="nav"
        display="flex"
        alignItems="center"
        backgroundColor={({palette}) => palette.primary.transparent}
        // position="absolute"
        // static | relative | absolute | fixed
        // width="inherit"
      >
        <Hidden mdDown>
          <Box
            flex="1 1 0"
          >
            <ul style={styles.list}>
              {menu.map(({name, url, color}, i) => (
                <li 
                  key={i} 
                  style={styles.listItems}
                >
                  <Link name={name} url={url} color={color} width={"auto"} />
                </li>
              ))}
            </ul>
          </Box>

          {/* <Box sx={{flexGrow: 1}} /> */}

          <Box 
            display="flex"
            // flex="1 1 0" 
            justifyContent="flex-end"
            color="white"
          >
            <IconButton
              color="secondary"
              href="/login"
            >
              <PersonIcon />
            </IconButton>

            <Box p={0.8} />

            <Button 
              sx={{borderRadius:0, marginRight:2}}
              variant="outlined" 
              color="inherit"
              href="/register"
            >
              Get Started
            </Button>
          </Box>
        </Hidden>
      </Box>
    </Container>
  );
};

const styles = {
  list: {
    display: "flex", 
    listStyle: "none", 
    margin: 0,
    padding: 5.5,
    // border: "1px red solid"
  },
  listItems: {
    padding: 3,
    textTransform: "uppercase",
    // border: "1px pink solid"
  },
  links: {
    color: "white",
    textDecoration: "none"
  }
};