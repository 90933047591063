import React, { useEffect, useState } from "react";
import { Reveal } from "react-gsap";
import { Box, Container } from "@mui/material";

import { FadeInLeft } from "src/animations";

import "./index.css";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({sections, backgroundColor}) => {
  const [sticky, setSticky] = useState(false);
  const [currentSection] = useState("#section-0");

  const handleSkip = (e, section) => {
    e.preventDefault()
    e.stopPropagation()

    if (section === "top") {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else {
      document.querySelector(currentSection).scrollIntoView({ behavior: 'smooth' });
    }
  } 


  useEffect(() => {
    const header = document.getElementById("wrapper");
    const topBarHeight = 80;
    const stickyHeaderOffset = header.offsetTop + topBarHeight;
    
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > stickyHeaderOffset) {
        header.classList.add("sticky");
        if (!sticky) {
          setSticky(true);
        }
      } 
      else {
        header.classList.remove("sticky");
        setSticky(false);
      }
    });

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box 
      className={sticky ? "header" : "none"}
      backgroundColor={({palette}) => palette.primary.main}
    >
      <Container>
        <Box id="wrapper">
          <Reveal repeat trigger={<div />}>
            <FadeInLeft>
              <Box>
                <img src="/images/logo-full-white.svg" alt="Kurai Medical" width={200}/>
              </Box>
            </FadeInLeft>
          </Reveal>
          
          <Box 
            onClick={(e) => handleSkip(e, "top")}
            sx={{cursor:"pointer"}}
          >
            <img src="/images/arrow-up.svg" alt="UP" width={30} />
          </Box>
          {/* <Box onClick={(e) => handleSkip(e)} sx={{cursor:"pointer"}}>
            <img src="/images/arrow-down.svg" alt="DOWN" width={50} />
          </Box> */}
        </Box>
    </Container>
    </Box>
  );
};