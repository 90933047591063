import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Hidden } from '@mui/material';

import MobileMenu from './MobileMenu';
// import DesktopMenu from './DesktopMenu';

const NavBar = ({ onMobileClose, openMobile }) => {
  
  const drawerWidth = "calc(100% - 0px)";
    
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          sx={{ 
            // width: 500,
            // height: 'calc(20% - 64px)',
            // height: 281
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <MobileMenu onMobileClose={onMobileClose} />
        </Drawer>
      </Hidden>
      {/* <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {DesktopMenu}
        </Drawer>
      </Hidden> */}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;