import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Container, Grid, Hidden, Stack, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { StateContext } from 'src/contexts';
import { Banner, Page } from 'src/components';

import Pricing from "./Pricing";
import Info from './Info';
import SideBar from './SideBar';
import { default as Downloads } from './SideBar/Downloads';
import { default as SideBarBanner } from './SideBar/Banner';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { services } = React.useContext(StateContext);
  const { index } = useParams();

  const i = Number(index) - 1;

  const service = (typeof services[i] === "undefined") ? services[0] : services[i];
  
  const bannerTitle = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Box 
          display="flex"
          sx={{
            boxShadow: "5px 5px 15px 3px rgba(0, 0, 0, 0.5)",
            transition: "300ms ease, 300ms ease transform",
            transform: `translateY(-5px)`,
          }}
        >
          <img alt={service.title} src={service.image} width={"100%"} />
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Stack pl={2} height="100%" justifyContent="center">
          <Typography variant="h1" color="white">{service.title}</Typography>
          <Typography variant="body2">{service.desc}</Typography>
        </Stack>
      </Grid>
    </Grid>
  ); 

  return (
    <Page
      title="About"
    >
      <Banner
        title={bannerTitle}
      />
    
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <SideBar />
          </Grid>

          <Grid item xs={12} md={8}>
            <Box
              mb={{md:15}}
              p="0 10px"
            >
              {/* <img alt={service.title} src={service.image} width="100%" />
              <Typography variant="h3" mt={2}>{service.title}</Typography> 
              <Typography variant="body2">{service.desc}</Typography> */}
              <Box p={1} />
              <Typography variant="h3">Lorem ipsum dolor</Typography>
              <Typography variant="p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur 
                eget leo at velit imperdiet varius. In eu ipsum vitae velit congue 
                iaculis vitae at risus. Nullam tortor nunc, bibendum vitae semper a, 
                volutpat eget massa. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Integer fringilla, orci sit amet posuere auctor, orci eros 
                pellentesque odio, nec pellentesque erat ligula nec massa. Aenean 
                consequat lorem ut felis ullamcorper posuere gravida tellus faucibus. 
                Maecenas dolor elit, pulvinar eu vehicula eu, consequat et lacus. 
                Duis et purus ipsum. In auctor mattis ipsum id molestie. Donec risus 
                nulla, fringilla a rhoncus vitae, semper a massa. Vivamus ullamcorper, 
                enim sit amet consequat laoreet, tortor tortor dictum urna, ut egestas 
                urna ipsum nec libero. Nulla justo leo, molestie vel tempor nec, egestas 
                at massa. Aenean pulvinar, felis porttitor iaculis pulvinar, odio orci 
                sodales odio, ac pulvinar felis quam sit.
              </Typography>

              <Typography variant="h3">Pricing</Typography>

              <Typography variant="p">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat 
                ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh 
                cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus 
                a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat 
                consequat.
              </Typography>

              <Box>
                <Pricing />
              </Box>

              <Box>
                <Info />
              </Box>
            </Box>

            <Hidden mdUp>
              <Downloads viewOnMobile={true}/>
            </Hidden>

            <Hidden mdUp>
              <SideBarBanner viewOnMobile={true}/>
            </Hidden>

            <Hidden mdUp>
              <nav aria-label="services-below">
                <Box
                  mt={3}
                  display="flex"
                  flexWrap="wrap"
                >
                  <Box 
                    display="flex"
                    alignItems="center"
                    component="a"
                    href="/services"
                    mr={1}
                    mb={1}
                    p={2}
                    color="white"
                    backgroundColor={({palette}) => palette.primary.main}
                    sx={{textDecoration: "none"}}
                  >
                    <ArrowBackIcon />
                  </Box>

                  {services.map(({title, url}, id) => (
                    <Box 
                      key={id}
                      component="a"
                      href={url}
                      p={2}
                      mr={1}
                      mb={1}
                      // flex="1 1 0"
                      border="1px lightgray solid"
                      sx={{textDecoration: "none"}}
                    >
                      <Typography mb={0}>{title}</Typography>
                    </Box>
                  ))}          
                </Box>
              </nav>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};