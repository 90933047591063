import React from 'react';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';

import { styled } from '@mui/material/styles';
import { Section } from 'src/components';

import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';

import DownloadIcon from '@mui/icons-material/Download';
import ToolBar from './ToolBar';
import { Avatar, Box } from '@mui/material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



function createData(id, date, type, image, title) {
  return {id, date, type, image, title};
};

function getRandomInt() {
  return Math.floor(Math.random() * 25000);
}

const data = [
  createData("01", "20/01/2022", "Magazine", "/images/mg-1.jpg", "Lorem ipsum dolor sit amet, consectetur adipiscing elit"),
  createData("02", "12/01/2022", "Magazine", "/images/mg-1.jpg", "Curabitur eget leo at velit imperdiet varius"),
  createData("03", "22/01/2022", "Magazine", "/images/mg-1.jpg", "In eu ipsum vitae velit congue iaculis vitae at risus"),
  createData("04", "02/01/2022", "Newsletter", "/images/mg-1.jpg", "Nullam tortor nunc, bibendum vitae semper a, volutpat eget"),
  createData("05", "07/01/2022", "Brochure", "/images/mg-1.jpg", "Lorem ipsum dolor sit amet, consectetur adipiscing elit"),
  createData("06", "01/01/2022", "Magazine", "/images/mg-1.jpg", "Integer fringilla, orci sit amet posuere auctor, orci eros"),
  createData("07", "19/01/2022", "Newsletter", "/images/mg-1.jpg", "Aenean consequat lorem ut felis ullamcorper posuere gravida"),
  createData("08", "25/01/2022", "Newsletter", "/images/mg-1.jpg", "Maecenas dolor elit, pulvinar eu vehicula eu, consequat et "),
  createData("09", "31/01/2022", "Magazine", "/images/mg-1.jpg", "Duis et purus ipsum lorem"),
  createData("10", "18/01/2022", "Magazine", "/images/mg-1.jpg", "In auctor mattis ipsum id molestie"),
  createData("11", "20/01/2022", "Magazine", "/images/mg-1.jpg", "Vivamus ullamcorper, enim sit amet consequat laoreet, tortor"),
  createData("12", "01/01/2022", "Brochure", "/images/mg-1.jpg", "Nulla justo leo, molestie vel tempor nec, egestas at massa"),
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [downloads] = React.useState(data);

  return (
    <Section
      p={{xs:3, md:5}}
      justifyContent="flex-start"
      height="auto"
    >
      <ToolBar />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Published</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {downloads.map((r, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  <Box display="flex" alignItems={"center"}>
                    <Avatar
                      src={r.image}
                      sx={{mr:1}}
                    />
                    {r.title}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{r.type}</StyledTableCell>
                <StyledTableCell>{r.date}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
    // <Box p={{xs:3, md:5}}>
    //   <Box mb={2}>
    //     <ToolBar />
    //   </Box>

    //   <Grid container spacing={5} justifyContent={"center"}>
    //     {downloads.map(({id, date, type, image, label}) => (
    //       <Grid item key={id} xs={10} md={3}>
    //         <Card>
    //           {/* <CardHeader 
    //             title={type}
    //             action={<Typography variant="caption">{date}</Typography>}
    //           /> */}
    //           <CardMedia
    //             component="img"
    //             // height="200"
    //             image={image}
    //             alt={type}
    //             title={type}
    //           />
    //           <CardContent>
    //             <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
    //               <Typography variant="caption">{type}</Typography>
    //               <Typography variant="caption">{date}</Typography>
    //             </Box>
    //             <Typography variant="body1" color="GrayText">{label.substring(0, 28)}</Typography>
    //           </CardContent>
    //           <Divider />                
    //           <CardActions>
    //             <Typography variant="body2" color="GrayText">{getRandomInt()} downloads</Typography>
    //             <Box flexGrow={1} />
    //             <IconButton aria-label="download material">
    //               <DownloadIcon />
    //             </IconButton>
    //           </CardActions>
    //         </Card>
    //       </Grid>
    //     ))}        
    //   </Grid>
    // </Box>
  );
};