import React from 'react';

import { Page } from 'src/components';

import Address from './Address';
import Map from './Map';
import RequestForm from './RequestForm';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Page 
      title="Contact us"
      description=""
    > 
      <Map />
      <Address />
      <RequestForm />
    </Page>
  );
}
