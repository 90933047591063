import React from "react";
import { Box, Container, Divider, Grid, Hidden, Stack, Typography } from "@mui/material";
import { Section } from "src/components";

import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
// import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

import Form from "./Form";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {  
  return (
    <Section 
      height="auto"
      // backgroundColor="rgba(116, 210, 212, 0.274)"
      // p={{xs:"35px 0px", md: "50px 225px"}}
    >
      <Container sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Stack 
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h5">GET IN TOUCH</Typography>
              <Typography variant="h2" mb={2}>
                Contact Us For Further Information
              </Typography>
              <Typography>
                Donec velit. Mauris massa. Vestibulum non nulla. Nam suscipit arcu nec elit. 
                Phasellus sollicitudin iaculis ante. Ut non mauris et sapien tincidunt adipiscing.
                Vestibulum vitae leo.
              </Typography>

              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Stack color="gray">
                      <img alt="Location" src="/images/location.png" width={48} />
                      <Typography variant="h5" color="primary" p="5px 0">Location</Typography>
                      <Typography variant="body2">1st Floor Kukunda Hse</Typography>
                      <Typography variant="body2">Cnr Samora Machel &amp; Julius St</Typography>
                      <Typography variant="body2">Harare</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Stack color="gray">
                      <img alt="Business Hours" src="/images/calendar.png" width={48} />
                      <Typography variant="h5" color="primary" p="5px 0">Business Hours</Typography>
                      <Typography variant="body2">SUN - MON : 8am - 7pm</Typography>
                      <Typography variant="body2">TUES - THU : 8am - 7pm</Typography>
                      <Typography variant="body2">FRI-SAT : 8am - 2pm</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack 
                      color="gray"
                      direction={{xs:"row", sm:"column"}}
                      justifyContent="center"
                    >
                      <Hidden mdDown>
                        <img alt="Social" src="/images/social.png" width={48} />
                        <Typography variant="h5" color="primary" p="5px 0">Social</Typography>
                      </Hidden>
                      <Box 
                        mt={{xs:4, sm:0}} 
                        display={{xs:"flex", sm:"block"}}
                        justifyContent="space-evenly"
                        width="100%"
                      >
                        <Box display="flex" alignItems="center">
                          <LinkedInIcon color="primary" fontSize="small" />
                          <Typography variant="body2">Linked In</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <TwitterIcon color="primary" fontSize="small" />
                          <Typography variant="body2">Twitter</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <FacebookIcon color="primary" fontSize="small" />
                          <Typography variant="body2">Facebook</Typography>
                        </Box>

                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" mt={3}>
                      <Box flex="1 1 0">
                        <Typography variant="body3">CALL FRONT DESK</Typography>
                        <Box display="flex" alignItems="center">
                          <PhoneForwardedIcon fontSize="large" color="primary" />
                          <Typography variant="body1" mb={0}>+263 773 194 748</Typography>
                        </Box>
                      </Box>                      
                      <Divider orientation="vertical" flexItem sx={{marginRight:2}}/>
                      <Box flex="1 1 0">
                        <Typography variant="body3">DROP US AN EMAIL</Typography>
                        <Box display="flex" alignItems="center">
                          <EmailIcon fontSize="large" color="primary" />
                          <Typography variant="body1" mb={0}>info@kuraimedical.co.zw</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <Box p={{xs:5, md:0}} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack 
              p={{xs:4, md:0}}
              justifyContent="center" 
              height="100%"
              backgroundColor="#e3e3e3"
            >
              <Form />
            </Stack>
          </Grid>
        </Grid>        
      </Container>
    </Section>
  );
};