import React from "react";
import Slider from "react-slick";
import { Box, Container, Grid, Typography } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ReactComponent as FullMedCover } from "src/svg/carousel/full-med-cover.svg";
import { ReactComponent as EasyAccess } from "src/svg/carousel/easy-access.svg";
import { ReactComponent as MedicalInsurance } from "src/svg/carousel/medical-insurance.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    arrows: false,
  };

  return (
    <Box
      // backgroundColor="#343434"
      backgroundColor={({palette}) => palette.secondary.main}
      height={480}
      pt={{xs:5}}
      sx={{
        backgroundImage: "url(/images/world-map.png)", 
        backgroundRepeat: "no-repeat", 
        backgroundPositionX: "center",
        // backgroundPositionY: "10px"
      }}
    >
      <Container>
        <Slider {...settings}>
          <Box sx={styles.container}>
            <Grid container height={"100%"}>
              <Grid item xs={12} md={6} sx={styles.image}>
                <Box width={"95%"}>
                  <img alt="Best Medicare" src="/images/full-medicare.png" width="100%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={styles.text}>
                <Box>
                  <Typography variant="h5" mb={2}>
                    We Provide
                  </Typography>
                  
                  {/* <img alt="Full Medical Care" src="/images/car-full-med-cover.svg" /> */}
                  <FullMedCover />
                                    
                  <Typography variant="body2" mt={2}>
                    Helping thousands of people to get high-class medical services. 
                    Your health is your most important asset.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={styles.container}>
            <Grid container height={"100%"}>
              <Grid item xs={12} md={6} sx={styles.image}>
                <Box width={"55%"}>
                  <img alt="Best Medicare" src="/images/car-easy-access.png" width="100%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={styles.text}>
                <Box>
                  <Typography variant="h5" mb={2}>
                    Experience
                  </Typography>
                  
                  {/* <img alt="Easy Access" src="/images/car-easy-access.svg" /> */}
                  <EasyAccess />
                  
                  <Typography variant="body2" mt={2}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Curabitur eget leo at velit imperdiet varius.
                    In eu ipsum vitae velit congue iaculis vitae at risus.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={styles.container}>
          <Grid container height={"100%"}>
              <Grid item xs={12} md={6} sx={styles.image}>
                <Box width={"65%"}>
                  <img alt="Best Medicare" src="/images/medical-insurance.png" width="100%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={styles.text}>
                <Box>
                  <Typography variant="h5" mb={2}>
                    Enjoy Quality
                  </Typography>
                  
                  {/* <img alt="Quality Medical Insurance" src="/images/car-medical-insurance.svg" /> */}
                  <MedicalInsurance />
                  
                  <Typography variant="body2" mt={2}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Curabitur eget leo at velit imperdiet varius.
                    In eu ipsum vitae velit congue iaculis vitae at risus.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slider>
      </Container>
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    height: 445,
    // color: "#e1e1e1",
    // border: "1px aqua solid" 
  },
  image: {
    // padding: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    // border: "1px red solid"
  },
  text: {
    padding: 8,
    display: "flex",
    alignItems: "center",
    // border: "1px orange solid"
  }
};