import React from 'react';
import { 
  Box,
  Button,
  Container,
  Stepper,
  Step,
  StepButton,
  Typography,
 } from '@mui/material';

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useNavigate } from 'react-router-dom';


const steps = [
  { label: 'Payment', view: Step1 },
  { label: 'Profile', view: Step2 },
  { label: 'Settings', view: Step3 },
];

export default function HorizontalNonLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  // console.log("🚀 ~ file: index.js ~ line 26 ~ HorizontalNonLinearStepper ~ activeStep", activeStep)
  const [completed, setCompleted] = React.useState({});

  const navigate = useNavigate();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    let newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    //
    if (newActiveStep > (steps.length - 1)) {
      handleReset();
    }
    else {
      setActiveStep(newActiveStep);
    }
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    // setActiveStep(0);
    // setCompleted({});

    navigate("/member/overview");

  };

  const renderView = (view, props) => {
    const View = view;

    return <View {...props} />;
  }; 

  return (
    <Box p={5}>
      <Container maxWidth="md">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} >
            {steps.map(({label, view}, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <Box 
            pt={3}
            height={{xs:"100%", md:545, xl:750}} 
            // height={window.innerHeight - 150}
            // border="1px red solid"
          >
            {renderView(steps[activeStep].view, {foo:`bar${activeStep}`})}
          </Box>



          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                  </Button>
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography variant="caption" sx={{ display: 'inline-block' }}>
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <Button onClick={handleComplete}>
                        {completedSteps() === totalSteps() - 1
                          ? 'Finish'
                          : 'Complete Step'}
                      </Button>
                    ))}
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </Container>
    </Box>
  );
}
