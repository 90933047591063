import React from 'react';

import { Typography } from "@mui/material";
import { Banner, Page } from 'src/components';

import About from './About';
// import Section2 from './Section2';

// import CoreServices from '../services/CoreServices';
import Brands from './Brands';
import Services from "./Services";
import Team from "./Team";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Page
      title="About"
    >
      <Banner
        title={<Typography variant="h1">About Us</Typography>} 
      />

      <About />
      <Team />
      <Services />
      <Brands />
    </Page>
  );

}

