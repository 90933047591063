import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Section } from 'src/components';
import ToolBar from './ToolBar';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(id, name, surname, gender, relationship, dob) {
  return { id, name, surname, gender, relationship, dob };
}

const rows = [
  createData("247256", "Meagan", "Hondo", "F", "Wife", "23/08/2022"),
  createData("247256", "Timothy", "Hondo", "M", "Son", "23/08/2022"),
  createData("247255", "Susan", "Hondo", "F", "Daughter", "23/08/2022"),
  createData("247253", "Samuel", "Hondo", "M", "Father", "23/08/2022"),
  createData("247252", "Susana ", "Hondo", "F", "Mother", "23/08/2022"),
];

export default function CustomizedTables() {
  return (
    <Section
      p={{xs:3, md:5}}
      justifyContent="flex-start"
    >
      <ToolBar />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Surname</StyledTableCell>
              <StyledTableCell>Gender</StyledTableCell>
              <StyledTableCell>Relationship</StyledTableCell>
              <StyledTableCell align="center">DOB</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {r.id}
                </StyledTableCell>
                <StyledTableCell>{r.name}</StyledTableCell>
                <StyledTableCell>{r.surname}</StyledTableCell>
                <StyledTableCell>{r.gender}</StyledTableCell>
                <StyledTableCell>{r.relationship}</StyledTableCell>
                <StyledTableCell align="center">{r.dob}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
}
