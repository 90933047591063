import React from 'react';

import { ReactComponent as Ambulance } from "src/svg/services/ambulance.svg";
import { ReactComponent as Dental } from "src/svg/services/dental.svg";
import { ReactComponent as Family } from "src/svg/services/family.svg";
import { ReactComponent as Maternity } from "src/svg/services/maternity.svg";
import { ReactComponent as Optician } from "src/svg/services/optician.svg";
import { ReactComponent as Senior } from "src/svg/services/senior.svg";

let state = {
  backgroundColor: "#104E21",
  backgroundRGBA: "rgba(16, 78, 33, 0.8)",
  dummyRole: "member",
  user: {
    username: "j.kudu@test.co.zw",
    role: "member",
    token: "798620360293097309273097",
    name: "James",
    surname: "Kudu",
    avatar: null,
    profile: {
      mobile: "0771 123 456"
    },
    // settings: {},
    notifications: []
  },
  menu: [
    { name: "Home", url: "/", color: "#ffffff"},
    { name: "About", url: "/about", color: "#ffffff"},
    { name: "Packages", url: "/packages", color: "#ffffff"},
    { name: "Services", url: "/services", color: "#ffffff"},
    { name: "Discover", url: "/discover", color: "#ffffff"},
    { name: "FAQ", url: "/faq", color: "#ffffff"},
    { name: "Contact", url: "/contact", color: "#ffffff"},
  ],
  services: [
    {
      icon: Family,
      image: "/images/services/family.jpg",
      imageSmall: "/images/services/50x50/family.jpg",
      url: "/services/1",
      title: "Family Cover",
      desc: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id"
    },
    {
      icon: Ambulance,
      image: "/images/services/ambulance.jpg",
      imageSmall: "/images/services/50x50/ambulance.jpg",
      url: "/services/2",
      title: "Ambulance Services",
      desc: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id"
    },
    {
      icon: Maternity,
      image: "/images/services/maternity.jpg",
      imageSmall: "/images/services/50x50/maternity.jpg",
      url: "/services/3",
      title: "Maternity",
      desc: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id"
    },
    {
      icon: Optician,
      image: "/images/services/opticians.jpg",
      imageSmall: "/images/services/50x50/opticians.jpg",
      url: "/services/4",
      title: "Opticians",
      desc: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id"
    },
    {
      icon: Dental,
      image: "/images/services/dental.jpg",
      imageSmall: "/images/services/50x50/dental.jpg",
      url: "/services/5",
      title: "Dental Clinics",
      desc: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id"
    },
    {
      icon: Senior,
      image: "/images/services/senior.jpg",
      imageSmall: "/images/services/50x50/senior.jpg",
      url: "/services/6",
      title: "Senior Citizen",
      desc: "Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id"
    }
  ]
};

export default React.createContext(state);