import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Section } from 'src/components';

import ToolBar from './ToolBar';
import { formatter } from 'src/common';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(ref, id, patient, type, claimant, amount, date) {
  return { ref, id, patient, type, claimant, amount, date };
}

const rows = [
  createData("A2203", "247253", "Samuel Hondo", "Beneficiary", "St Andrews Clinic", 100, "23/08/2022"),
  createData("A2202", "247253", "Samuel Hondo", "Beneficiary", "St Andrews Clinic", 99.89, "23/08/2022"),
  createData("A2201", "247253", "Samuel Hondo", "Beneficiary", "St Andrews Clinic", 10.50, "23/08/2022"),
  createData("A2200", "247253", "Samuel Hondo", "Beneficiary", "St Andrews Clinic", 500, "23/08/2022"),
  createData("A2199", "247253", "Samuel Hondo", "Beneficiary", "St Andrews Clinic", 10.89, "23/08/2022"),
  createData("A2198", "247253", "Samuel Hondo", "Beneficiary", "St Andrews Clinic", 50.90, "23/08/2022"),
];

export default function CustomizedTables() {
  return (
    <Section
      p={{xs:3, md:5}}
      justifyContent="flex-start"
    >
      <ToolBar />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Reference</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Patient</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Claimant</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {r.ref}
                </StyledTableCell>
                <StyledTableCell>{r.id}</StyledTableCell>
                <StyledTableCell>{r.patient}</StyledTableCell>
                <StyledTableCell>{r.type}</StyledTableCell>
                <StyledTableCell>{r.claimant}</StyledTableCell>
                <StyledTableCell>{formatter.format(r.amount)}</StyledTableCell>
                <StyledTableCell>{r.date}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
}
