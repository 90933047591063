import React,{ useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useSwipeable } from 'react-swipeable';

import "./index.css";

// export const CarouselItem = ({children, width }) => {
//   return (
//     <div 
//       className="carousel-item"
//       style={{width: width}}
//     >
//       {children}
//     </div>
//   );
// };

const Carousel = ({children, index}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        // updateIndex(activeIndex + 1);
      }
      
    }, 1000);

    return () => {
      if (interval)
        clearInterval(interval);
    };

  }, [activeIndex, paused]);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    }
    else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    
    setActiveIndex(newIndex);
  };

  const handleSwipes = useSwipeable({
    onSwipeLeft: () => updateIndex(activeIndex + 1),
    onSwipeRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div 
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
      {...handleSwipes }
    >
      <div id="navigation">
        <div id="left" className="arrows" onClick={() => updateIndex(activeIndex - 1)} />
        <div id="right" className="arrows" onClick={() => updateIndex(activeIndex + 1)} />
      </div>

      <div 
        className="inner" 
        style={{
          // transition: "500ms ease, 500ms ease transform",
          transform: `translateX(-${activeIndex * 100}%)`,
        }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%"});
        })}
      </div>
      
      <div className="indicators">
        {/* <button onClick={() => updateIndex(activeIndex - 1)}>Prev</button> */}
        {React.Children.map(children, (child, index) => (
          <button
            className={index === activeIndex ? "active" : ""}
            onClick={() => updateIndex(index)}
          >
            {/* {index + 1} */}
          </button>
        ))}
        {/* <button onClick={() => updateIndex(activeIndex + 1)}>Next</button> */}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number
};

Carousel.defaultProps = {
  index: 0
};

export default Carousel;