import React, { useContext } from "react";
import { Box, Button, Container, Grid, Typography, useTheme } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { StateContext } from 'src/contexts';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { palette } = useTheme();
  const { services } = useContext(StateContext);

  return (
    <Box
      backgroundColor={palette.secondary.contrast}
      marginTop={5}
      // padding={20}
      // color="white"
      textAlign="center"
    >
      <Container>
        <Box p={5}>
          <Typography variant="h1">Services</Typography>
          <Typography>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</Typography>
        </Box>
        
        <Grid 
          container
          spacing={2}
        >
          {services.map((s, i) => (
            <Grid key={i} item xs={12} md={4} style={styles.item}>
              <Box
                backgroundColor="white"
                pb={3}
                sx={{
                  boxShadow: "3px 3px 10px 3px rgba(0, 0, 0, 0.5)",
                  // transition: "transform 0.3s"
                  // transition: "300ms ease, 300ms ease transform",
                  // transform: `translateY(-1px)`,
                }}
              >
                <Box>
                  <img alt={s.title} src={s.image} width="100%" />
                </Box>
                <Box>
                  <Typography mt={1} mb={1} variant="h5">{s.title}</Typography>
                  <Typography variant="body2">{s.desc}</Typography>
                </Box>
                <Box mt={3}>
                  <Button
                    href={s.url}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Learn More
                  </Button>
                </Box>
              </Box>
            </Grid>))}
        </Grid>
        

      </Container>
      
    </Box>
  );
};

const styles = {
  item: {
    // flex: "0 0 0px",
    paddingBottom: 70,
    // border: "1px white solid",
    textAlign: "center",
    // borderRadius: "50%",
  }
};