import React from "react";
import { Box, Container, Grid, Hidden, Typography } from "@mui/material";

const data = [
  {bg: "#4a7eaf", title: "General Practitioners", icon: "/images/general-prac.svg"},
  {bg: "#4a8fc0", title: "Pharmaceutical", icon: "/images/pharmacy.svg"},
  {bg: "#68b2e7", title: "Hospitals", icon: "/images/hospital.svg"},
  {bg: "#364f6b", title: "Medical Equipment", icon: "/images/medical-equipment.svg"},
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Container>

      <Hidden mdDown>
        <Box
          color="white"
          display="flex"
          flexDirection={{xs:"column", md:"row"}}
          alignItems="center"
          backgroundColor="#4a7eaf"
        >
          <Box 
            flex={"1 0 0"}
            width={{xs:"100%"}}
            height={80}
            display="flex"
            // justifyContent="space-between"
            alignItems="center"
            backgroundColor="#364f6b"
          >
            <Box ml={2} mr={2}>
              <img alt="medical icon" src="/images/medical-icon-heart.png" />
            </Box>

            <Typography m={0}>Discover</Typography>
            <Box flexGrow={1} />
          </Box>

          <Box 
            sx={{
              display: "block",
              top: 0,
              // right: "-40px",
              width: 0,
              height: 0,
              borderTop: "40px solid transparent",
              borderBottom: "40px solid transparent",
              borderLeft: "40px solid #364f6b", //364f6b
              // zIndex: 5
          }}
          />
          
          {data.map(({bg, title}, i) => (
            <Box 
              key={i} 
              flex={"1 0 0"} 
              width={{xs:"100%"}}
              height={80}
              display="flex"
              justifyContent="center"
              alignItems="center"
              backgroundColor={bg}
            >
              <Typography m={0}>{title}</Typography>
            </Box>
          ))}
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box mt={3} />
        
        <Grid container>
          <Grid item xs={12}>

            <Box
              display="flex"
              alignItems="center"
            >
              <Box 
                width={150} 
                display="flex"
              >
                <img 
                  alt="Kurai Medical Professionals" 
                  src="/images/doctor.jpeg" //
                  width="100%"
                />
              </Box>
              <Box p={2}>
                <Box 
                  display="flex"
                  alignItems="center"
                >
                  {/* <Box mr={1}>
                    <img src="/images/medical-icon-heart-blue.png" width={32} />
                  </Box> */}

                  <Typography m={0} variant="h3">Discover</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Curabitur eget leo at velit imperdiet varius. 
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          {data.map(({bg, title, icon}, i) => (
            <Grid item xs={6}
              key={i} 
              // flex={"1 0 0"} 
              // width={{xs:"100%"}}
              height={80}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              color="white"
              backgroundColor={bg}
            >
              {/* <Box> */}
                <img alt={title} src={icon} width={30} />
                <Typography m={0}>{title}</Typography>
              {/* </Box> */}
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </Container>
  );
};