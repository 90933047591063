import PropTypes from "prop-types";

import "./index.css";

const Banner = ({icon, title,  content, color}) => {
  return (
    <div class="icon-card-wrapper">
      <div id="icon">
        {icon}
      </div>
      <div class="icon-card-desc">
        <div id="title">
          {title}
        </div>
        <div id="content">
          {content}
        </div>

      </div>
    </div>
  );
};

Banner.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.any,
  content: PropTypes.any,
  color: PropTypes.any,

  // width: PropTypes.any,
  // height: PropTypes.any,
  // background: PropTypes.string,
  // title: PropTypes.node
};

Banner.defaultProps = {};

export default Banner;