const { Tween } = require("react-gsap");

const FadeInLeft = ({ children }) => (
  <Tween
    from={{ transform: 'translate3d(-100vw, 0, 0)' }}
    // to={{ autoAlpha: 1 }}
    ease="back.out(1)"
  >
    {children}
  </Tween>
);

export default FadeInLeft;