import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { formatter } from 'src/common';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6} md={3}>
          <Box>
            <Typography variant="body2">Members</Typography>
            <Typography variant="h3">150</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} md={3}>
          <Box>
            <Typography variant="body2">Total Claims</Typography>
            <Typography variant="h3">2000</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} md={3}>
          <Box>
            <Typography variant="body2">Subscriptions</Typography>
            <Typography variant="h3">{formatter.format(20223.55)}</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} md={3}>
          <Box>
            <Typography variant="body2">Arrears</Typography>
            <Typography variant="h3">{formatter.format(1002.10)}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};