import React from "react";
import { Typography } from "@mui/material";
import { Banner } from "src/components";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {  
  return (
    <Banner
      background="/images/services.jpg"
      title={<Typography variant="h1">Frequently Asked Questions</Typography>} 
    />
    
  );
};