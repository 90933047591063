import React from 'react';
import { Stack } from '@mui/material';

import Totals from './Totals';
import Subscriptions from './Subscriptions';
import YearlySummary from './YearlySummary';
import Claims from './Claims';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Stack 
      padding="25px 20px"
      spacing={4}
    >
      <Totals />
      {/* <Subscriptions /> */}
      <YearlySummary />
      <Claims />
    </Stack>
  );
};