/**
 * This function is supposed to handle a scroll action 
 * to the element below the intro section
 * 
 * Its currently not working coz it seems javascript has 
 * another technique of referencing the window object
 */

import PropTypes from 'prop-types';

const Scroller = ({element, offset}) => {
  // document.querySelector("#section-1").scrollIntoView({behavior: "smooth", block: "start"})

  // const height = element.getBoundingClientRect().height;
    
  element.scrollTo({top: 100 + offset, behavior: 'smooth'});

  // console.log({
  //   height: height,
  //   final: height + offset
  // });
};

Scroller.propTypes = {
  element: PropTypes.element.isRequired,
  offset: PropTypes.number
};

Scroller.defaultProps = {
  offset: 0
};

export default Scroller;