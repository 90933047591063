import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';


function createData(id, icon, label) {
  return {id, icon, label};
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const icons = [
  <InfoIcon color="info" />,
  <NotificationsIcon color="error" />,
  <EmailIcon color="action" />,
];

const data = [
  createData("01", icons[getRandomInt(3)], "Lorem ipsum dolor sit amet, consectetur adipiscing elit"),
  createData("02", icons[getRandomInt(3)], "Curabitur eget leo at velit imperdiet varius"),
  createData("03", icons[getRandomInt(3)], "In eu ipsum vitae velit congue iaculis vitae at risus"),
  createData("04", icons[getRandomInt(3)], "Nullam tortor nunc, bibendum vitae semper a, volutpat eget massa"),
  createData("05", icons[getRandomInt(3)], "Lorem ipsum dolor sit amet, consectetur adipiscing elit"),
  createData("06", icons[getRandomInt(3)], "Integer fringilla, orci sit amet posuere auctor, orci eros pellentesque odio"),
  createData("07", icons[getRandomInt(3)], "Aenean consequat lorem ut felis ullamcorper posuere gravida tellus faucibus"),
  createData("08", icons[getRandomInt(3)], "Maecenas dolor elit, pulvinar eu vehicula eu, consequat et lacus"),
  createData("09", icons[getRandomInt(3)], "Duis et purus ipsum"),
  createData("10", icons[getRandomInt(3)], "In auctor mattis ipsum id molestie"),
  createData("11", icons[getRandomInt(3)], "Donec risus nulla, fringilla a rhoncus vitae, semper a massa"),
  createData("12", icons[getRandomInt(3)], "Vivamus ullamcorper, enim sit amet consequat laoreet, tortor tortor dictum urna."),
  createData("13", icons[getRandomInt(3)], "Nulla justo leo, molestie vel tempor nec, egestas at massa"),
  createData("14", icons[getRandomInt(3)], "Aenean pulvinar, felis porttitor iaculis pulvinar, odio orci sodales odio, ac pulvinar felis quam si"),
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [notifications, setNotifications] = React.useState(data);

  const removeNotification = (id) => {
    console.log("id", id);    
    setNotifications(notifications.filter((n) => n.id !== id));
  };

  return (
    <Box p={{xs:3, md:5}}>
      <Box mb={2}>
        <Typography variant="h3">Notifications</Typography>
      </Box>

      <Stack
        alignItems={"center"}
        spacing={2}
      >
        {notifications.map(({id, icon, label}, i) => (
          <Box 
            key={i}
            display="flex"
            alignItems="center"
            width={{xs:"95%", md:480}}
            p={2}
            borderRadius={1}
            backgroundColor={({palette}) => palette.secondary.main}
          >
            <Box p={{xs:1, md:3}}>
              {icon}
            </Box>
            <Box>
              <Typography>{label}</Typography>
            </Box>
            <Box flexGrow={1} />
            <IconButton
              onClick={() => removeNotification(id)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}        
      </Stack>
    </Box>
  );
};