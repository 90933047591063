import React, { useState } from 'react';
import { Box, Chip, IconButton, MenuItem, Stack, Typography } from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import data from './data';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [listing, setListing] = useState(data['places']);
  const [group, setGroup] = useState("places");
  const [open, setOpen] = useState(true);

  const { filter } = data;

  const filterList = (item) => { 
    const index = item.toLowerCase();

    setListing(data[index]);
    setGroup(index);

    setOpen(true);
  };

  return (
    <Box pl={1}>
      <nav aria-label="services">
        <Stack>
          <Typography variant="caption" mt={3}>FILTER BY:</Typography>

          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Box>
              {filter.map((item, i) => (
                <Chip 
                  label={item} key={i} sx={{marginRight: 1, marginBottom: 1, padding: 1, cursor: "pointer"}} 
                  color="secondary" 
                  size="medium"
                  onClick={() => filterList(item)}
                />
              ))}
            </Box>

            <Box>
              <IconButton
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <ArrowUpIcon /> : <ArrowDownIcon />} 
              </IconButton>
            </Box>
          </Box>

          {/* <Divider sx={{margin:"15px 0"}} /> */}
          <Box m="15px 0" />
          
          <Box 
            display={open ? "flex" : "none"}
            flexWrap="wrap"
            alignContent="flex-start"
            height={{xs:"auto", md:500}}
            sx={{
              md: {overflowY: "scroll"}
            }}
          >
            {listing.map(({name}, id) => (
              <Box 
                key={id}
                // component="a"
                display="flex"
                // justifyContent="space-between"
                alignItems="flex-start"
                // href={url}
                p={1.2}
                mr={1}
                mb={1}
                // flex="1 1 0"
                border="1px lightgray solid"
                sx={{
                  textDecoration: "none",
                  ":hover": {
                    boxShadow: "1px 5px 15px 3px rgba(0, 0, 0, 0.5)",
                    // transition: "transform 0.3s"
                    transition: "300ms ease, 300ms ease transform",
                    transform: `translateY(-1px)`,
                    cursor: "pointer"
                  }
                }}
              >
                <Typography variant="body2" color="primary">
                  {name}
                </Typography>
                <ArrowForwardIcon fontSize="small" color="secondary" />
              </Box>
            ))}
          </Box>

        </Stack>
      </nav>
    </Box>
  );
};