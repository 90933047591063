import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Banner } from "src/components";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {  
  return (
    <Banner
      height={300}
      bgPosY="13%"
      background="/images/services.jpg"
      title={
        <Container maxWidth="sm">
          <Box 
            width="100%"
            // color={({palette}) => palette.primary.main}
          >
            <Box>
              <Typography variant="h1">
                Hello, Guest! 
              </Typography>
            </Box>
            
            <Box>
              <Typography variant="body1">
                Enter your credentials to sign in into your account
              </Typography>
            </Box>
          </Box>
        </Container>
    }
    />
    
  );
};