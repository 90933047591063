import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  coords: [
    {
      id: 1,
      coord: { lat: 20.27, lng: -157 },
      content: (
        <>
          <h3>MediLabs Vitale (Pvt) Ltd</h3>
          <span>22 Sullfock Avenue, Waterfalls</span>
        </>
      )
    },
    {
      id: 2,
      coord: { lat: 21.27, lng: -157 },
      content: (
        <>
          <h3>Top Pharmacy</h3>
          <span>Cnr 1st Street &amp; Health Road, Greendale</span>
        </>
      )
    },
    {
      id: 3,
      coord: { lat: 20.27, lng: -156 },
      content: (
        <>
          <h3>Surreal Health Clinic</h3>
          <span>45 Lorem Ispum Drive, Westgate</span>
        </>
      )
    },
    {
      id: 4,
      coord: { lat: 20.27, lng: -158 },
      content: (
        <>
          <h3>Dr Matilda Njere</h3>
          <span>Room 5, Mansions Shopping Complex, Harare</span>
        </>
      )
    }
  ]
};
