import React from 'react';
import { Box, Typography, useTheme } from "@mui/material";

const Pill = ({icon: Icon, label}) => {
  const { palette } = useTheme();

  return (
    <Box
      mr={1.5}
      mb={1.5}
      p={1}
      display="flex" 
      alignItems="center"
      border={`1px ${palette.secondary.main} solid`}
      width="auto"
      sx={{
        boxShadow: "2px 5px 8px 3px rgba(0, 0, 0, 0.2)"
      }}
    >
      <Box
        mr={1}
        width={25}
      >
        <Icon fill={`${palette.primary.main}`} width="100%" />
      </Box>
      <Typography color="primary" variant="body3">{label}</Typography>
    </Box>
  );
};

Pill.defaultProps = {};

export default Pill;