import React, { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  IconButton
} from '@mui/material';

import { makeStyles } from '@mui/styles'; 

import NavItem from './NavItem';
import menu from './menu';
import { StateContext } from 'src/contexts';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    // position: "absolute",
    marginTop: 64,
    width: 256,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  // const navigate = useNavigate();
  const { user, dummyRole } = useContext(StateContext);
  console.log("🚀 ~ file: index.js ~ line 50 ~ NavBar ~ dummyRole", dummyRole)
  const [expanded, setExpanded] = useState("General-panel");
  
  const icons = menu("icons");
  const items = menu(dummyRole) ?? [];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  //

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to={"#"}
        />
        <Typography
          // className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name} {user.surname}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {dummyRole.toUpperCase()}
        </Typography>
      </Box>
      
      <Divider />
      
      {/* <Box
        display="flex"
        justifyContent="space-evenly"
      >
        {icons.map(({title, url, icon}) => (
          <IconButton 
            title={title}
            key={title}
            // onClick={() => navigate(url)}
          >
            {icon}
          </IconButton>  
      ))}

      </Box> */}
      
      <Box p={2}>
        {items.map((item, i) => (
          <Accordion 
            key={i}
            expanded={expanded === `${item.category}-panel`}
            onChange={handleChange(`${item.category}-panel`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${item.category}-panelbh-content`}
              id={`${item.category}-panelbh-header`}
              sx={{flexDirection:'row-reverse'}}
            >
              <Box 
                display={"flex"} 
                justifyContent="space-between" 
                alignItems={"center"}
                width={"100%"}
                >
                <Typography>{item.category}</Typography>
                {item.icon}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {item.links.map((link) => (
                  <NavItem
                    href={link.url}
                    key={link.title}
                    title={link.title}
                    icon={link.icon}
                    count={link.title === 'Notifications' && user.notifications.length}
                  />
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      
      <Box flexGrow={1} />

      <Box
        p={2}
        m={2}
        // bgcolor="background.dark"
      >
        <Box
          display="flex"
          justifyContent="center"
          mt={0}
        >
          <Button
            color="primary"
            component="a"
            // href="/#/admin/logout"
            href="/login"
            variant="contained"
          >
            Sign Out
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;