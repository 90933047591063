import React from "react";
import Slider from "react-slick";
import { Box, Container, Typography } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const brands = [
  {
    image: "/images/brands/brand-1.png",
    link: "/#",
    title: "Consectetur adipiscing elit"    
  },
  {
    image: "/images/brands/brand-2.png",
    link: "/#",
    title: "Consectetur adipiscing elit"    
  },
  {
    image: "/images/brands/brand-3.png",
    link: "/#",
    title: "Consectetur adipiscing elit"    
  },
  {
    image: "/images/brands/brand-4.png",
    link: "/#",
    title: "Consectetur adipiscing elit"    
  },
  {
    image: "/images/brands/brand-5.png",
    link: "/#",
    title: "Consectetur adipiscing elit"    
  },
  {
    image: "/images/brands/brand-6.png",
    link: "/#",
    title: "Consectetur adipiscing elit"    
  }
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Box
      // pt={5}
      // pb={8}
      textAlign="center"
      mb={18}
    >
      <Container>
        <Box p={5}>
          <Typography variant="h1">Associated Brands</Typography>
          <Typography>Lorem ipsum dolor sit amet consectetuer adipiscing elit.</Typography>
        </Box>
        
        <Slider {...settings}>
          {brands.map((s, i) => (
            <Box key={i}>
              <img alt={s.title} src={s.image} width="85%" />
            </Box>            
          ))}
        </Slider>
      </Container>
    </Box>
  );
};
