import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(desc, basic, standard, pro) {
  return { desc, basic, standard, pro };
}

const rows = [
  createData('Lorem ipsum dolor sit amet, consectetur adipiscing elit', 160.00, 250.00, 540.00),
  createData('Lorem ipsum dolor sit amet, consectetur adipiscing elit', 237.00, 300.00, 670.00),
  createData('Lorem ipsum dolor sit amet, consectetur adipiscing elit', 262.00, 310.0, 524.00),
  createData('Lorem ipsum dolor sit amet, consectetur adipiscing elit', 305.00, 407.00, 670.00),
  createData('Lorem ipsum dolor sit amet, consectetur adipiscing elit', 356.00, 416.00, 490.00),
];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell align="right">Basic</StyledTableCell>
            <StyledTableCell align="right">Standard</StyledTableCell>
            <StyledTableCell align="right">Professional</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell component="th" scope="row">
                {row.desc}
              </StyledTableCell>
              <StyledTableCell align="right">{row.basic}</StyledTableCell>
              <StyledTableCell align="right">{row.standard}</StyledTableCell>
              <StyledTableCell align="right">{row.pro}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
