import React, { useContext, useState } from 'react';

import { Link as RouterLink, Navigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Box,
  CircularProgress,
  Link,
  FormHelperText,
  Typography,
  // makeStyles,
} from '@mui/material';

import { LargeButton, Page, TextField } from 'src/components';

// import { useGlobalState } from 'src/hooks';
import { DispatchContext } from 'src/contexts';

// import { useAuth } from 'src/hooks';

// const styles = {
//   root: {
//     backgroundColor: theme.palette.background.dark,
//     height: '100%',
//     paddingBottom: theme.spacing(3),
//     paddingTop: theme.spacing(3)
//   }
// }));


const LoginView = () => {
  // // const classes = useStyles();
  
  // const { isAuthentic, authenticate } = useAuth();
  

  const dispatch = useContext(DispatchContext);
  
  const [authentic, setAuthentic] = useState(false);
  const [path, setPath] = useState("");

  let { email } = useParams();
  
  const defaultUser = process.env.REACT_APP_USER;
  const defaultPass = process.env.REACT_APP_PASS;
  
  return (
    authentic //isAuthentic 
    ? <Navigate 
        to={path}
      />
    : <Page
        // className={classes.root}
        title="Login"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          pt={{xs:1, md:5}}
        >
          <Formik
            initialValues={{
              email: "", //(typeof email === 'undefined') ? defaultUser : email,
              password: "", //defaultPass,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values, actions) => {
              //TEST

              if (values.email === "admin@test.com") {
                setPath("/admin/dashboard");

                await dispatch({type: "set-user-role", payload: "admin"});
              } 
              else {
                setPath("/member/onboarding");
              }

              setAuthentic(true);
              // const url = `${urls.baseURL}/auth/login`;

              const credentials = {
                username: values.email, 
                password: values.password
              };
  
              // await authenticate(credentials, actions, "email");
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Hello, Guest! 
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  mb={1}
                >
                  <Typography
                    // align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Enter your credentials to sign in into your account
                  </Typography>

                </Box> */}
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  // helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />

                {Boolean(touched.email && errors.email) && (<FormHelperText error>{errors.email}</FormHelperText>)}
                {Boolean(touched.email && errors.email === 'Not activated') && (
                  <FormHelperText>
                    To activate your account <a href={`/#/register/confirm/${values.email}`}>click here</a>
                  </FormHelperText>
                )}
                
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Typography
                    // color="secondary"
                    variant="caption"
                  >
                    <a href="/#/forgot">Forgot Password?</a>
                  </Typography>
                </Box>
                
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />

                <Box my={2}>
                  <LargeButton
                    color="primary"
                    disabled={isSubmitting}
                    endIcon={isSubmitting ? <CircularProgress size={20} thickness={5} /> : null}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    { isSubmitting ? "Signing in ... " : "Sign in now" }
                  </LargeButton>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Box>
      </Page>
  );
};

export default LoginView;
