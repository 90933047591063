import React, { useEffect } from "react";
// import { marker } from "leaflet";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import data from "./data";

const customMarker = new L.icon({
  // iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  iconUrl: "/images/marker.png",
  iconSize: [25, 41],
  iconAnchor: [13, 0]
});

const Map = (props) => {
  const [done, setDone] = React.useState(false);
  const markerRefs = React.useRef({});
  const { coords } = data;

  useEffect(() => {
    let params = new URLSearchParams(document.location.search.substring(1));
    let id = params.get("id");
    if (id && done) {
      const markerToOpen = markerRefs.current[0];
      markerToOpen.openPopup();
    }
  }, [done]);

  return (
    <MapContainer
      doubleClickZoom={false}
      scrollWheelZoom={false}
      id="mapId"
      zoom={7}
      center={[20.27, -157]}
      style={{ width: "100%", height: "100%", minHeight: 400, zIndex:0 }}
    >
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
        attribution="Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri"
      />
      {coords.map((marker, index) => {
        return (
          <Marker
            key={marker.id}
            id={marker.id}
            position={marker.coord}
            icon={customMarker}
            ref={(ref) => {
              markerRefs.current[marker.id] = ref;
              if (index === data.length - 1 && !done) {
                setDone(true);
              }
            }}
          >
            <Popup>{marker.content}</Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default Map;
