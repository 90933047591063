import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { HelmetProvider } from 'react-helmet-async';

import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import 'leaflet/dist/leaflet.css'

//

const loader = document.querySelector('.loader');

const showLoader = () => loader.classList.remove('loader--hide');
const hideLoader = () => loader.classList.add('loader--hide');

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App 
        showLoader={showLoader}
        hideLoader={hideLoader}
      />
    </HelmetProvider>
  </React.StrictMode>,
);

// ReactDOM.render(
//   <React.StrictMode>
//     <HelmetProvider>
//       <App 
//         showLoader={showLoader}
//         hideLoader={hideLoader}
//       />
//     </HelmetProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
