import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Section } from 'src/components';

import ToolBar from './ToolBar';
import { formatter } from 'src/common';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(ref, description, amount, bal, date) {
  return { ref, description, amount, bal, date };
}

const rows = [
  createData("A2203247253", "Subscription", 100, 0.00, "23/08/2022"),
  createData("A2202247253", "Subscription", 99.89, 100, "23/08/2022"),
  createData("A2201247253", "Subscription", 10.50, 0.00, "23/08/2022"),
  createData("A2200247253", "Subscription", 500, 0.00, "23/08/2022"),
  createData("A2199247253", "Subscription", 10.89, 50, "23/08/2022"),
  createData("A2198247253", "Subscription", 50.90, 0.00, "23/08/2022"),
];

export default function CustomizedTables() {
  return (
    <Section
      p={{xs:3, md:5}}
      justifyContent="flex-start"
    >
      <ToolBar />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Reference</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell align="right">Amount</StyledTableCell>
              <StyledTableCell align="right">Balance</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {r.ref}
                </StyledTableCell>
                <StyledTableCell>{r.description}</StyledTableCell>
                <StyledTableCell align="right">{formatter.format(r.amount)}</StyledTableCell>
                <StyledTableCell align="right">{formatter.format(r.bal)}</StyledTableCell>
                <StyledTableCell align="center">{r.date}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
}
