import React from "react";
import { StateContext } from "src/contexts";

function reducer(state, action) {
  switch(action.type) {
    case "set-menu-color":
      return { ...state, backgroundColor: action.payload };
    case "set-user-role":
      return { ...state, dummyRole: action.payload };
    default:
      return { ...state, backgroundColor: "#052535" };
  }
}

const useState = () => {
  const initialState = React.useContext(StateContext);

  return React.useReducer(reducer, initialState);
};

export default useState;