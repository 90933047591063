import React, { useContext } from 'react';
import { Box, Container, Grid, IconButton, Typography, useTheme } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import PersonIcon from '@mui/icons-material/Person';

import { StateContext } from 'src/contexts';
import { Logo } from 'src/components';

// import RequestForm from './RequestForm';
import Link from "./Link";

const items = [
  { icon: <CallIcon color="white" />, title: "CONTACT NUMBER", desc: "(+263) 27899123980" },
  { icon: <EmailIcon color="white" />, title: "EMAIL ADDRESS", desc: "info@kuraimedical.co.zw" },
  { icon: <LocationOnIcon color="white" />, title: "BUSINESS ADDRESS", desc: "1st Floor Kukunda Hse, Harare" },
];

const Footer = () => {
  const { services, menu } = useContext(StateContext);
  const { palette } = useTheme();
  
  return (
    <Box
      component="footer"
      alignItems="center"
      color="#ffffff"
      minHeight={100}
      backgroundColor="#343434"
      sx={{
        backgroundImage: "url(/images/world-map.png)", 
        backgroundRepeat: "no-repeat", 
        backgroundPositionX: "center",
        // backgroundPositionY: "10px"
      }}
    > 
      <Container>
        <Box 
          padding={{xs:"30px 0px", lg:"60px 0px", xl:"80px 0px"}}
        >
          <Grid 
            container={true}
            spacing={3} 
          >
            <Grid item xs={12} md={3}>
              <Logo width={"100%"} fill={palette.secondary.main} />
              <Typography>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. 
                Cras et ipsum quis mi semper accumsan.
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <Typography variant="h5" mt="20px">Services</Typography>
              
              <Box m="3px 0px">
                {/* <Typography variant="h5">Overview</Typography> */}
                <ul style={styles.list}>
                  {services.map(({title, url}, i) => (
                    <li 
                      key={i} 
                      style={styles.listItems}
                    >
                      <Link name={title} url={url} />
                    </li>
                  ))}
                </ul>
              </Box>
            
              <Box mt={2}>
                <Typography variant="h5">Legal</Typography>
                <ul style={styles.list}>
                  <li style={styles.listItems}>
                    <Link name="Privacy Policy" url="/privacy" color="white" />
                  </li>
                  <li style={styles.listItems}>
                    <Link name="Terms &amp; Conditions" url="/terms" color="white" />
                  </li>
                </ul>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <Typography variant="h5" mt="20px">Pages</Typography>
              
              <Box m="3px 0px">
                {/* <Typography variant="h5">Overview</Typography> */}
                <ul style={styles.list}>
                  {menu.map(({name, url, color}, i) => (
                    <li 
                    key={`item${i}`} 
                      style={styles.listItems}
                    >
                      <Link name={name} url={url} color={color} />
                    </li>
                  ))}
                </ul>
              </Box>
            
              <Box mt={2}>
                <Typography variant="h5">Portal</Typography>
                <ul style={styles.list}>
                  <li style={styles.listItems}>
                    <Link name="Sign in" url="/login" color="white" />
                  </li>
                  <li style={styles.listItems}>
                    <Link name="Register" url="/register" color="white" />
                  </li>
                </ul>
              </Box>
            </Grid>

            

            <Grid item xs={12} md={3}>
              <Typography variant="h5" m="20px 0 18px 0">Contacts</Typography>
              <Box 
                // display="flex"
                // alignItems="center"
              >
                {items.map(({icon, title, desc}, i) => (
                  <Box 
                    key={i} 
                    display="flex"
                    mb={1.5}
                  >
                    <Box pr={1} display="flex" alignItems="center">
                      {icon}
                    </Box>
                    <Box display="flex" alignItems="center">
                      {/* <Tooltip title={title} placement="top"> */}
                        <Typography variant="body2" lineHeight={0}>
                          {desc}
                        </Typography>
                      {/* </Tooltip> */}
                    </Box>
                  
                  </Box>
                ))}
              </Box>
              <Box
                mt={3}
                display="flex"
                justifyContent={"space-between"}
                width={{xs:"40%", lg:"35%"}}
              >
                <IconButton
                  sx={{color:"white", border:"1px white solid", marginRight:2}}
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  sx={{color:"white", border:"1px white solid", marginRight:2}}
                >
                  <TwitterIcon />
                </IconButton>
                <IconButton
                  sx={{color:"white", border:"1px white solid", marginRight:2}}
                >
                  <FacebookIcon />
                </IconButton>
                
                {/* <InstagramIcon fontSize="large" /> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      
      <Box
        padding="0 30px"
        backgroundColor="#000000"
        height={50}
        sx={{opacity:0.5}}
      >
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={50}
          >
            <Typography variant="body3">
              Kurai Medical &copy; All Rights Reserved (2022)
            </Typography>
            <Typography variant="body3">
              Designed by: Devco
            </Typography>
          </Box>

        </Container>

        
      </Box>
    </Box>
  );
};

const styles = {
  list: {
    // display: "flex", 
    // listStyle: "none", 
    margin: 0,
    paddingLeft: 1
  },
  links: {
    color: "white",
    textDecoration: "none"
  },
  listItems: {
    listStyleType: "none"
  }
};

export default Footer;