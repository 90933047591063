import React from 'react';
import { Box, Container } from '@mui/material';
import { Page } from 'src/components';

import Banner from "./Banner";
import Form from "./Form";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Page 
      title="Registration"
    >
      <Banner />
      
      <Container maxWidth="sm">
        <Form />
      </Container>
      
      <Box p={5} />
    </Page>
  );
}
