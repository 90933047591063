import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerIcon from '@mui/icons-material/PowerSettingsNewRounded';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import LockIcon from '@mui/icons-material/Lock';
// import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UsersIcon from '@mui/icons-material/Group';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import UploadFileIcon from '@mui/icons-material/UploadFile';
// import MailIcon from '@mui/icons-material/Mail';
import BlogsIcon from '@mui/icons-material/Description';
import FeedbackIcon from '@mui/icons-material/RecordVoiceOver';
// import BuildIcon from '@mui/icons-material/Build';
import StoresIcon from '@mui/icons-material/Store';
import CategoriesIcon from '@mui/icons-material/AccountTree';
import BrandsIcon from '@mui/icons-material/FactCheck';
// import LightIcon from '@mui/icons-material/Light';
import ProductsIcon from '@mui/icons-material/Lightbulb';

// import { MdDashboard } from "react-icons/md";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ProfileIcon from '@mui/icons-material/Person';

// import { GiReceiveMoney } from "react-icons/gi";
// import { RiBankLine } from "react-icons/ri";
// import Categories from 'src/common/data/Categories';

import PeopleIcon from '@mui/icons-material/PeopleAltRounded';
import WorkshopIcon from '@mui/icons-material/BuildRounded';
import InventoryIcon from '@mui/icons-material/InventoryRounded';
// import SalesIcon from '@mui/icons-material/LocalAtm';
import SalesIcon from '@mui/icons-material/RequestQuote';
import ReportIcon from '@mui/icons-material/Assessment';

import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

const menu = (role) => {
  const items = {
    "icons": [
      { 
        title: "Dashboard",
        url: "/app/dashboard",
        icon: <DashboardIcon />
      },
      { 
        title: "Notifications",
        url: "/app/notifications",
        icon: <NotificationsIcon />
      },
      { 
        title: "Settings",
        url: "/app/settings",
        icon: <SettingsIcon />
      },
      { 
        title: "Sign Out",
        url: "/app/sign-out",
        icon: <PowerIcon />
      },
    ],
    "admin":[
      {
        category: "General",
        icon: <DashboardIcon />,
        links: [
          { title: "Dashboard", url: "/admin/dashboard" },
          { title: "Notifications", url: "/admin/notifications" },
          { title: "Downloads", url: "/admin/downloads" },
        ]
      },
      {
        category: "People",
        icon: <PeopleIcon />,
        links: [
          { title: "Members", url: "/admin/members" },
          { title: "Users", url: "/admin/users" },
        ]
      },
      {
        category: "Reports",
        icon: <ReportIcon color="primary" />,
        links: [
          { title: "Claims", url: "#"},
          { title: "Payments", url: "#"},
          { title: "Arrears", url: "#"},
        ]
      },
      {
        category: "Account",
        icon: <MiscellaneousServicesIcon />,
        links: [
          { title: "Profile", url: "/member/profile" },
          { title: "Settings", url: "/member/settings" },
        ]
      }
    ],
    "member": [
      {
        category: "General",
        icon: <DashboardIcon />,
        links: [
          { title: "Overview", url: "/member/overview" },
          { title: "Notifications", url: "/member/notifications" },
          { title: "Downloads", url: "/member/downloads" },
        ]
      },
      {
        category: "Management",
        icon: <MedicalServicesIcon />,
        links: [
          { title: "Beneficiaries", url: "/member/beneficiaries" },
          { title: "Claims", url: "/member/claims" },
          { title: "Payments", url: "/member/payments" },
        ]
      },
      {
        category: "Account",
        icon: <ProfileIcon />,
        links: [
          { title: "Profile", url: "/member/profile" },
          { title: "Settings", url: "/member/settings" },
        ]
      }
    ],
    "assistant": [],
    "mechanic": [],
    "": []
  };

  return items[role];
};

export default menu;


/* 

+ People
- Customers
- Suppliers
- Mechanics
- Users

+ Workshop
- Vehicles
- Bookings
- Job Cards

+ Inventory
- Parts
- Inventory
- Markup Codes
- Price Matrix
- Price Control
- Re-order

+ Sales
- Quotations
- Invoices
- Receipts
- Statements

+ Reports
- EOD
- EOW
- EOM
- Monthly

*/