import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Box, CircularProgress, Grid, MenuItem, Typography } from '@mui/material';
import { LargeButton, TextField } from 'src/components';

const titles = [
  'Mr', 
  'Mrs', 
  'Ms', 
  'Prof', 
  'Dr', 
  'Eng', 
  'Pastor'
];

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [success, setSuccess] = useState(false);
  const [busy, setBusy] = useState(false);

  return (
    <Box>
      <Typography variant="h1">Basic Info</Typography>
      <Typography variant="h5" mb={2}>We want to know a little about you. (Your can update this later)</Typography>
      
      <Box>
        <Formik
          initialValues={{
            title: "",
            gender: "",
            landline: "",
            address: "",
            emergencyContact: "",
            emergencyMobile: "",
            method: "",
            mobile: "",
            serverValidationErrors: false
          }}
          validationSchema={
            Yup.object().shape({
              mobile: Yup.string().max(255).required("Your mobile number is required"),
              method: Yup.string().required("Select a payment method"),
            })
          }
          onSubmit={async (values, actions) => {
            // data 
            await handleSubmit(values, actions);

            // dummy 
            setBusy(true);
            setSuccess(false);

            await setTimeout(() => {
              setBusy(false);
              setSuccess(true);
            }, 2000);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            // setValues,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth={true}
                    name="title"
                    label="Title"
                    margin="none"
                    type="text"
                    variant="outlined"
                    value={values.title}
                    onChange={handleChange}
                    error={Boolean(errors.title)}
                    helperText={Boolean(errors.title) && errors.title}
                  >
                    {titles.map((title, i) => <MenuItem key={i} value={title}>{title}</MenuItem>)}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth={true}
                    name="gender"
                    label="Gender"
                    margin="none"
                    type="text"
                    variant="outlined"
                    value={values.gender}
                    onChange={handleChange}
                    error={Boolean(errors.gender)}
                    helperText={Boolean(errors.gender) && errors.gender}
                  >
                    {["Male", "Female"].map((gender, i) => <MenuItem key={i} value={gender}>{gender}</MenuItem>)}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    name="landline"
                    label="Landline"
                    margin="none"
                    type="text"
                    variant="outlined"
                    value={values.landline}
                    onChange={handleChange}
                    error={Boolean(errors.landline)}
                    helperText={Boolean(errors.landline) && errors.landline}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth={true}
                    name="address"
                    label="Residential Address"
                    margin="none"
                    type="text"
                    variant="outlined"
                    value={values.address}
                    onChange={handleChange}
                    error={Boolean(errors.address)}
                    helperText={Boolean(errors.address) && errors.address}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    name="emergencyContact"
                    label="Emergency Contact"
                    placeholder="Name of person to call in an emergency"
                    margin="none"
                    type="text"
                    variant="outlined"
                    value={values.emergencyContact}
                    onChange={handleChange}
                    error={Boolean(errors.emergencyContact)}
                    helperText={Boolean(errors.emergencyContact) && errors.emergencyContact}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    name="emergencyMobile"
                    label="Emergency Mobile"
                    placeholder="The mobile number of the Emergency Contact"
                    margin="none"
                    type="text"
                    variant="outlined"
                    value={values.emergencyMobile}
                    onChange={handleChange}
                    error={Boolean(errors.emergencyMobile)}
                    helperText={Boolean(errors.emergencyMobile) && errors.emergencyMobile}
                  />
                </Grid>
                
                <Grid item xs={12} md={3}>
                  <LargeButton
                    // sx={{padding:4}} 
                    color="primary"
                    // disabled={isSubmitting}
                    // endIcon={isSubmitting ? <CircularProgress color="secondary" size={20} thickness={8} /> : null}
                    
                    disabled={busy}
                    endIcon={busy ? <CircularProgress color="secondary" size={20} thickness={8} /> : null}

                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {/* {isSubmitting ? "Processing..." : "Make Payment"} */}
                    {busy ? "Processing..." : "Update Profile"}
                  </LargeButton>
                </Grid>
                
                <Grid item xs={12}>
                  {success && ( 
                  <Alert severity="info">
                    {`TODO: 1) Validate payment 2) Record transaction`}
                  </Alert>)}
                </Grid>

              </Grid>
            </form>
          )}
        </Formik>
      </Box>
      
    </Box>
  );
};

function handleSubmit(values, actions) 
{  
  // axios.post(`${urls.baseURL}/feedback`, values).then((response) => {
  //   const result = response.data;
    
  //   if (result.success) {  
  //     actions.resetForm({"serverValidationErrors": false});
  //     actions.setStatus("Your feedback has been submitted to us. Thank you for your time!");
  //   }
  //   else {
  //     actions.setFieldValue("serverValidationErrors", true);
  //     actions.setStatus("You have invalid entries");

  //     Object.keys(result.data).map(field => actions.setFieldError(field, result.data[field][0]));
  //   }
  // })
  // .catch(error => {
  //   actions.setFieldValue("serverValidationErrors", true);
  //   actions.setStatus(`Your ${values.type} cannot be accepted at the moment. Try again in a few minutes`);
  // })
  // .finally(() => {
  //   actions.setSubmitting(false); 
  // });
}