import React from 'react';

import { Box, Container, Grid, Typography } from "@mui/material";
import { Banner, Page } from 'src/components';

import SearchBox from './SearchBox';
import Map from './Map';
import Intro from './Intro';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Page
      title="Discover"
    >
      <Banner
        title={<Typography variant="h1">Discover</Typography>} 
      />

      <Container>
        <Intro />

        <Grid container minHeight={500}>
          <Grid item xs={12} md={4}>
            <SearchBox />
          </Grid>
          <Grid item xs={12} md={8}>
            <Map />
          </Grid>
        </Grid>

        <Box p={5} />
      </Container>
    </Page>
  );

}

