import React from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  Typography
} from '@mui/material';

// import { makeStyles } from '@mui/styles'; 
import theme from "src/theme";
import Badge from '@mui/material/Badge';

const styles = {
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    // border: "1px blue solid"
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    // padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',

  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
};

const NavItem = ({ className, href, icon: Icon, title, count,...rest }) => {
  // const classes = useStyles();
  const navigate = useNavigate();

  return (
    <ListItem
      className={clsx(styles.item, className)}
      disableGutters
      {...rest}
    >
      <div
        // activeClassName={styles.active}
        className={styles.button}
        // component={RouterLink}
        // to={href}
        onClick={() => navigate(href)}
      >
        {Icon && (
          <Icon
            className={styles.icon}
            size="20"
          />
        )}
        <span className={styles.title}>
          <Typography 
            color="primary"
            sx={{cursor: "pointer"}}
          >
            {title}
          </Typography>
        </span>
        
        {count > 0 && <Badge badgeContent={count} color="error"></Badge>}
      </div>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
