import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { MainLayout, DashboardLayout } from "src/layout";

import { 
  Home,
  About,
  Packages,
  Services,
  ServiceItem,
  Discover,
  Register,
  Login,
  Faq,
  Contact,
  NotFound,
  // Privacy,
  // Terms
  Member,
  Onboarding,
  Overview,
  Notifications,
  Downloads,
  Beneficiaries,
  Claims,
  Payments,
  Profile,
  Settings,
  // Reports,
  // Chatroom,
  //
  Admin,
  AdminNotifications,
  AdminDownloads,
  AdminMembers,
  AdminUsers,
  // AdminClaims,
  // AdminPayments,
  // AdminArrears,
  // AdminProfile,
  // AdminSettings,
} from 'src/pages';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="*" element={ <NotFound /> } />
          <Route path="/" element={ <Home /> } />
          <Route path="/about" element={ <About /> } />
          <Route path="/packages" element={ <Packages /> } />
          <Route path="/services" element={ <Services /> } />
          <Route path="services/:index" element={ <ServiceItem /> } />
          <Route path="/discover" element={ <Discover /> } />
          <Route path="/register" element={ <Register /> } />
          <Route path="/login" element={ <Login /> } />
          <Route path="/faq" element={ <Faq /> } /> 
          <Route path="/contact" element={ <Contact /> } /> 
          {/* <Route path="/privacy" element={ <Privacy /> } /> */}
          {/* <Route path="/terms" element={ <Terms /> } /> */}
        </Route>

        <Route path="/member/" element={<DashboardLayout />}>
          <Route path="overview" element={ <Overview /> } /> 
          <Route path="dashboard" element={ <Member /> } /> 
          <Route path="notifications" element={ <Notifications /> } />
          <Route path="downloads" element={ <Downloads /> } />
          <Route path="beneficiaries" element={ <Beneficiaries /> } />
          <Route path="claims" element={ <Claims /> } />
          <Route path="payments" element={ <Payments /> } />
          <Route path="profile" element={ <Profile /> } />
          <Route path="settings" element={ <Settings /> } />
        </Route>

        <Route path="/member/onboarding" element={ <Onboarding /> } /> 

        <Route path="/admin/" element={<DashboardLayout />}>
          <Route path="dashboard" element={ <Admin /> } />
          <Route path="notifications" element={ <AdminNotifications /> } />
          <Route path="downloads" element={ <AdminDownloads /> } />
          <Route path="members" element={ <AdminMembers /> } />
          <Route path="users" element={ <AdminUsers /> } />
          {/* <Route path="backend" element={ <AdminClaims /> } /> */}
          {/* <Route path="backend" element={ <AdminPayments /> } /> */}
          {/* <Route path="backend" element={ <AdminArrears /> } /> */}
          {/* <Route path="profile" element={ <AdminProfile /> } /> */}
          {/* <Route path="settings" element={ <AdminSettings /> } /> */}
        </Route>

      </Routes>
    </Router>
  );
};