import React from 'react';
import { Box, Typography } from "@mui/material";

import SupportAgentIcon from '@mui/icons-material/SupportAgent';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (  
  <Box
    mt={{xs:2, md:5}}
    p={3}
    backgroundColor={({palette}) => palette.primary.main}
    borderRadius={2}
    color="white"
    sx={{background: "url(/images/request-call-bg.jpg) center no-repeat", backgroundSize:"cover"}}
    {...props}
  >
    <SupportAgentIcon fontSize="large" />
    <Typography 
      fontSize={22} 
      fontWeight="bold"
    >
      (+263) 123 456 7890
    </Typography>
    <Typography
      component="a"
      href="mailto:info@kuraimedical.co.zw"
      color="secondary"
    >
      info@kuraimedical.co.zw
    </Typography>
    <Typography>
      We are here to help our customer any time. You can call on 24/7 To Answer Your Question.
    </Typography>
  </Box>
  );
};