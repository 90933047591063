import { createTheme } from "@mui/material/styles";
import { colors, responsiveFontSizes } from "@mui/material";

import shadows from "./shadows";
import typography from "./typography";

// dark 
// 104E21
// 396039

// light
// 1F953F
// 008b8b
// 8fbc8f


const theme = createTheme({
  palette: {
    background: {
      dark: "#123524",
      light: "#D4E5D4",
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: "#104E21",
      transparent: "rgba(16, 78, 33, 0.8)",
      contrast: "#CEF8D9"
    },
    secondary: {
      main: "#8fbc8f",
      cover: "#364f6b",
      contrast: "#e2e2e2"
      // contrast: colors.grey[300]
    },
    text: {
      primary: "#8fbc8f",
      secondary: colors.blueGrey[600]
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      ss: 400, // custom
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  },
  shadows,
  typography
});

export default responsiveFontSizes(theme);