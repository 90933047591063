import React from 'react';
import { useLocation, Navigate } from "react-router-dom";
// import { verifyJwt } from 'src/common';

import Layout from './Layout';

const DashboardLayoutSecured = () => {
  const location = useLocation();
  
  const verified = true; //verifyJwt();
  const pathname = location.pathname;
  
  return verified 
    ? <Layout />
    : <Navigate to="/login" state={{ path: pathname}} replace /> ;
};

export default DashboardLayoutSecured;
