import React from 'react';
import { Box, Typography } from '@mui/material';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Box
      height={200}
      p={10}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Typography
        variant="h1"
      >
        404 - Page Not Found
      </Typography>
      <Typography>
        You are trying to access a page not present on our site
      </Typography>
    </Box>
  );
};