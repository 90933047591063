import React from 'react';
// import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';

import { makeStyles } from '@mui/styles'; 

import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Email';
// import InputIcon from '@mui/icons-material/Mesasge';
// import HomeIcon from '@mui/icons-material/Home';
// import { Logo } from 'src/components';

import { ReactComponent as Logo } from "src/svg/logo-full-white.svg";
// import DashboardLogo from './DashboardLogo';

const useStyles = makeStyles(() => ({
  root: {
    height: 64
  },
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  notifications,
  ...rest }) => {
  
  const classes = useStyles();
  // const navigate = useNavigate();
  // const appName = process.env.REACT_APP_NAME;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Box>
          {/* <RouterLink to="/"> */}
          <Logo width={"80%"} />
          {/* <DashboardLogo width={50} height={50} /> */}
          {/* </RouterLink> */}
        </Box>
        
        {/* <IconButton
          color="inherit"
          onClick={() => navigate("/")}
        >
          <HomeIcon />
        </IconButton> */}
        
        <Box flexGrow={1} />
        
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="secondary"
            >
              <MessageIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit">
            {/* <InputIcon /> */}
          </IconButton>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
