import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { LargeButton, Section } from "src/components";

import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

const Description = () => {
  return (
    <Section
      id="description"
      height="auto"
    >
      <Container className="services">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack 
              height="100%"
              justifyContent={"center"}
            >
              <Stack justifyContent={"center"}>
                <Typography variant="h5">QUALITY MEDICAL AID</Typography>
                <Typography variant="h2" mt={1}>Our Services</Typography>
                <br />
                <Typography variant="p">
                  Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, 
                  nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus 
                  a sit amet mauris. Morbi accumsan ipsum velit.
                </Typography>
                <Box>
                  <LargeButton
                    component="a"
                    startIcon={<PhoneForwardedIcon />}
                    variant="contained"
                    onClick={() => document.querySelector("#request-form").scrollIntoView({ behavior: 'smooth' })}
                  >
                    Request a call
                  </LargeButton>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex" 
              justifyContent="flex-end"
            >
              <Box maxWidth={500}>
                <img alt="services" src="/images/services-about.jpg" width="100%" />
              </Box>

            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default Description;