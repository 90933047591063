import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from "gsap";

//
import { Box, Typography } from '@mui/material';

const LinkComponent = ({name, color, url, width, ...props}) => {
  const el = useRef();
  // const navigate = useNavigate();

  const q = gsap.utils.selector(el);

  const onEnter = (e) => {
    // gsap.fromTo(e, {fontSize: 16}, {fontSize: 18, ease: "easeOut"});
  };

  const onLeave = (e) => {
    // gsap.fromTo(e, {fontSize: 18}, {fontSize: 16});
  };

  return (
    <Box
      ref={el}
      p={"10px 20px"}
      // pb={0.8}
      display="flex"
      alignContent={"center"}
      alignItems="center"
      flexDirection={"column"}
      textAlign="center"
      sx={{cursor: "pointer"}}
      onMouseEnter={() => onEnter(q(".name"))}
      onMouseLeave={() => onLeave(q(".name"))}
      {...props}      
    >
      <a
        href={url}
        color="secondary"
        underline="none"
        style={{textDecoration: "none", width: width}}
      >
        <Typography className="name" variant="menu">
          {name}
        </Typography>

        {/* <Box 
          className="border"
          sx={{borderBottom: `8px ${color} solid`, opacity:0, width: "inherit"}}
        /> */}
      </a>
    </Box>
  );
};

LinkComponent.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  colors: PropTypes.array,
  width: PropTypes.any
};

LinkComponent.defaultProps = {
  color: "#FFFFFF",
  width: "100%"
};

//

export default LinkComponent;
