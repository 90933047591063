import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
} from '@mui/material';

import { DispatchContext, StateContext } from 'src/contexts';
import { Loader } from 'src/components';

const DialogBox = () => {
  const { messageBox } = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  
  const {
    busy,
    open,
    style,
    title,
    message,
    // callback
  } = messageBox;

  const handleConsent = () => {
    messageBox.callback();
    
    dispatch({type: "message-box-reset", payload: {}});
  };

  const handleClose = () => {
    dispatch({type: "message-box-reset", payload: {}});
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth={style.maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {(busy) ? "Processing ..." : title.toUpperCase()}
        </DialogTitle>
        <DialogContent>
          {(busy) ? <Loader /> :
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button 
            autoFocus
            variant="contained"
            onClick={() => handleConsent()} 
            disabled={busy}
          >
            Yes
          </Button>
          <Button 
            variant="contained"
            onClick={() => handleClose()} 
            disabled={busy}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogBox.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  // handleClose: PropTypes.func.isRequired,
};

DialogBox.defaultProps = {
  title: "Info",
};

export default DialogBox;