import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      // display: true,
      // text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: 100, max: 10000 })),
      // backgroundColor: 'rgba(255, 99, 132, 0.5)',
      backgroundColor: '#104E21',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      maxBarThickness: 10,
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: 100, max: 10000 })),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // backgroundColor: '#D4E5D4',
      barPercentage: 0.5,
      barThickness: 12,
      borderRadius: 4,
      categoryPercentage: 0.5,
      maxBarThickness: 10,
    },
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return <Bar options={options} data={data} />;
}


// import { Bar } from 'react-chartjs-2';
// import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// // eslint-disable-next-line import/no-anonymous-default-export
// export default (props) => {
//   const theme = useTheme();

//   const data = {
//     datasets: [
//       {
//         backgroundColor: '#3F51B5',
//         barPercentage: 0.5,
//         barThickness: 12,
//         borderRadius: 4,
//         categoryPercentage: 0.5,
//         maxBarThickness: 10,
//         data: [18, 5, 19, 27, 29, 19, 20],
//         label: 'This year',
//       },
//       {
//         backgroundColor: '#EEEEEE',
//         barPercentage: 0.5,
//         barThickness: 12,
//         borderRadius: 4,
//         categoryPercentage: 0.5,
//         data: [11, 20, 12, 29, 30, 25, 13],
//         label: 'Last year',
//         maxBarThickness: 10
//       }
//     ],
//     labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug', '7 aug']
//   };

//   const options = {
//     animation: false,
//     cornerRadius: 20,
//     layout: { padding: 0 },
//     legend: { display: false },
//     maintainAspectRatio: false,
//     responsive: true,
//     xAxes: [
//       {
//         ticks: {
//           fontColor: theme.palette.text.secondary
//         },
//         gridLines: {
//           display: false,
//           drawBorder: false
//         }
//       }
//     ],
//     yAxes: [
//       {
//         ticks: {
//           fontColor: theme.palette.text.secondary,
//           beginAtZero: true,
//           min: 0
//         },
//         gridLines: {
//           borderDash: [2],
//           borderDashOffset: [2],
//           color: theme.palette.divider,
//           drawBorder: false,
//           zeroLineBorderDash: [2],
//           zeroLineBorderDashOffset: [2],
//           zeroLineColor: theme.palette.divider
//         }
//       }
//     ],
//     tooltips: {
//       backgroundColor: theme.palette.background.paper,
//       bodyFontColor: theme.palette.text.secondary,
//       borderColor: theme.palette.divider,
//       borderWidth: 1,
//       enabled: true,
//       footerFontColor: theme.palette.text.secondary,
//       intersect: false,
//       mode: 'index',
//       titleFontColor: theme.palette.text.primary
//     }
//   };

//   return (
//     <Card {...props}>
//       <CardHeader
//         action={(
//           <Button
//             endIcon={<ArrowDropDownIcon fontSize="small" />}
//             size="small"
//           >
//             Last 7 days
//           </Button>
//         )}
//         title="Latest Sales"
//       />
//       <Divider />
//       <CardContent>
//         <Box
//           sx={{
//             height: 400,
//             position: 'relative'
//           }}
//         >
//           <Bar
//             data={data}
//             options={options}
//           />
//         </Box>
//       </CardContent>
//       <Divider />
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'flex-end',
//           p: 2
//         }}
//       >
//         <Button
//           color="primary"
//           endIcon={<ArrowRightIcon fontSize="small" />}
//           size="small"
//         >
//           Overview
//         </Button>
//       </Box>
//     </Card>
//   );
// };
