import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from "gsap";

//
import { Box, Typography } from '@mui/material';

const LinkComponent = ({name, color, url, width, ...props}) => {
  const el = useRef();
  // const navigate = useNavigate();

  const q = gsap.utils.selector(el);

  const onEnter = (e) => {
    gsap.fromTo(e, {opacity: 0.5}, {opacity:1, ease: "elastic"});
  };

  const onLeave = (e) => {
    gsap.fromTo(e, {opacity: 1}, {opacity:0.5});
  };

  return (
    <Box
      ref={el}
      // m={"0 25px"}
      // pb={0.8}
      display="flex"
      alignContent={"center"}
      alignItems="center"
      flexDirection={"column"}
      // textAlign="center"
      sx={{cursor: "pointer"}}
      onMouseEnter={() => onEnter(q(".text"))}
      onMouseLeave={() => onLeave(q(".text"))}
      {...props}      
    >
      <a
        href={url}
        color="secondary"
        underline="none"
        style={{textDecoration: "none", width: width}}
      >
        <Typography 
          className="text"
          variant="menu"
          sx={{opacity:0.5}}
        >
          {name}
        </Typography>
      </a>
    </Box>
  );
};

LinkComponent.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  colors: PropTypes.array,
  width: PropTypes.any
};

LinkComponent.defaultProps = {
  color: "#FFFFFF",
  width: "100%"
};

//

export default LinkComponent;
