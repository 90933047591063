import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DeleteIcon from '@mui/icons-material/Delete';

import { Section } from 'src/components';
import ToolBar from './ToolBar';
import { Chip } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(id, name, surname, gender, beneficiaries, pack, date) {
  return { id, name, surname, gender, beneficiaries, pack, date };
}

const rows = [
  createData("147256", "Samson", "Hondo", "F", 5, "Standard", "23/08/2022"),
  createData("147256", "Craig", "Gutu", "M", 3, "Standard", "23/08/2022"),
  createData("147255", "Samantha", "Gomo", "F", 4, "Basic", "23/08/2022"),
  createData("147253", "Donald", "Janga", "M", 6, "Pro", "23/08/2022"),
  createData("147252", "Praise", "Gore", "F", 1, "Standard", "23/08/2022"),
];

const colors = {
  Basic: "#006801",
  Standard: "#306597",
  Pro: "#7A3091"
};

export default function CustomizedTables() {
  return (
    <Section
      p={{xs:3, md:5}}
      justifyContent="flex-start"
    >
      <ToolBar />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Surname</StyledTableCell>
              <StyledTableCell>Gender</StyledTableCell>
              <StyledTableCell align="center">Beneficiaries</StyledTableCell>
              <StyledTableCell align="center">Package</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {r.id}
                </StyledTableCell>
                <StyledTableCell>{r.name}</StyledTableCell>
                <StyledTableCell>{r.surname}</StyledTableCell>
                <StyledTableCell>{r.gender}</StyledTableCell>
                <StyledTableCell align="center">{r.beneficiaries}</StyledTableCell>
                <StyledTableCell align="center">
                  <Chip
                    sx={{ backgroundColor: colors[r.pack], color: "white", p:1 }}
                    label={r.pack}
                    size="small"
                    />
                </StyledTableCell>
                <StyledTableCell align="center">{r.date}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
}
