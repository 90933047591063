import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Container } from '@mui/material';

const faq = [
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
  {
    question: "Ut commodo pulvinar veritatis occaecati mauris?", 
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."},
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Box p="50px 0">
        {faq.map(({question, answer}, i) => (
          <Accordion
            key={i}
            expanded={expanded === `panel-${i}`}
            onChange={handleChange(`panel-${i}`)}
            sx={({palette}) => ({
              border: `1px ${palette.primary.main} solid`,
              backgroundColor: palette.secondary.main,
              marginBottom: 2,
              boxShadow: "3px 5px 12px 1px rgba(0, 0, 0, 0.5)",
            })}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="primary" mb={0}>{question}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{backgroundColor: "white"}}
            >
              <Typography color="primary">{answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Box>
        <Typography variant="h2">Not Yet Answered?</Typography>
        <Typography>
          Donec velit. Mauris massa. Vestibulum non nulla. Nam suscipit arcu nec elit. 
          Phasellus sollicitudin iaculis ante. Ut non mauris et sapien tincidunt adipiscing. 
          Vestibulum vitae leo. Suspendisse nec mi tristique nulla laoreet vulputate.
        </Typography>
      </Box>
    </Container>
  );
}
