import React from 'react';
import { ReactComponent as Logo } from "./logo.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (
    // <img
    //   alt="Kurai Medical Logo"
    //   src="/images/logo.svg"
    //   {...props}
    // />
    <div>
      <Logo {...props} />
    </div>
  );
};