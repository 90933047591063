import { Container, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import "./index.css";

const Banner = ({height, title, bgPosX, bgPosY}) => {
  const { palette } = useTheme();

  return (
    <div 
      id="banner"
      style={{
        width: "100%",
        height: height,
        backgroundImage: "url(/images/world-map.png)",
        backgroundRepeat: "no-repeat",
        backgroundColor: palette.secondary.main,
        backgroundPositionX: bgPosX,
        backgroundPositionY: bgPosY,
      }}
    >
      <Container 
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div id="title">
          {/* <img alt="KM" src={image} /> */}
          {title}
        </div>
      </Container>
    </div>
  );
};

Banner.propTypes = {
  height: PropTypes.any, 
  image: PropTypes.string,
  title: PropTypes.node,
  bgPosX: PropTypes.any,
  bgPosY: PropTypes.any,
};

Banner.defaultProps = {
  height: 450,
  bgPosX: "center",
  bgPosY: 0,
  // image: "/"
};

export default Banner;