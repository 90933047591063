import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontSize: 12,
    // padding: 3,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
    // padding: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.background.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(desc, jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec) {
  return { desc, jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec };
}

const rows = [
  createData('Subscriptions', 1, 1, 1, 2, 2, 0, 1, 1, 1, 1, 1, 1),
  createData('Claims', 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1),
  createData('Beneficiary claims', 0, 0, 0, 1, 2, 1, 0, 0, 0, 1, 0, 1),
];

const styles = [
  {backgroundColor: "red"},
  {backgroundColor: "green"},
  {backgroundColor: "orange"}, 
];

const keys = ["Zero", "Done", "Warning"];


export default function CustomizedTables() {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Jan</StyledTableCell>
              <StyledTableCell>Feb</StyledTableCell>
              <StyledTableCell>Mar</StyledTableCell>
              <StyledTableCell>Apr</StyledTableCell>
              <StyledTableCell>May</StyledTableCell>
              <StyledTableCell>Jun</StyledTableCell>
              <StyledTableCell>Jul</StyledTableCell>
              <StyledTableCell>Aug</StyledTableCell>
              <StyledTableCell>Sep</StyledTableCell>
              <StyledTableCell>Oct</StyledTableCell>
              <StyledTableCell>Nov</StyledTableCell>
              <StyledTableCell>Dec</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {row.desc}
                </StyledTableCell>
                <StyledTableCell><Box sx={styles[row.jan]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.feb]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.mar]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.apr]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.may]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.jun]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.jul]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.aug]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.sep]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.oct]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.nov]} width={10} height={10} /></StyledTableCell>
                <StyledTableCell><Box sx={styles[row.dec]} width={10} height={10} /></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box 
        display="flex"
        mt={2}
      >
        {keys.map((item, i)=> (
          <Box key={i} display="flex" alignItems={"center"} pr={1}>
            <Box sx={styles[i]} width={10} height={10} />
            <Typography variant="caption" pl={1}>{item}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
