import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import DownloadIcon from '@mui/icons-material/CloudDownloadRounded';
import ArticleIcon from '@mui/icons-material/Article';

const createData = (name="", url="", size=0.0) => {
  return {name, url, size}
};

const downloads = [
  createData("Product Catalog", "#", 1.2),
  createData("Magazine", "#", 2.1),
];

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (
    <Box {...props}>
      <Stack
        display="flex"
      >
        <Box 
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          component="a"
          href="/services"
          mt={2}
          mb={1}
          p={2}
          color="white"
          backgroundColor={({palette}) => palette.primary.main}
          sx={{textDecoration: "none"}}
        >
          <Typography mb={0}>Downloads</Typography>
          <DownloadIcon />
        </Box>

        {downloads.map(({name, url, size}, id) => (
          <Box 
            key={id}
            component="a"
            display="flex"
            justifyContent="space-between"
            href={url}
            p={2}
            // mr={1}
            mb={1}
            // flex="1 1 0"
            border="1px lightgray solid"
            sx={{
              textDecoration: "none",
              ":hover": {
                boxShadow: "0px 0px 25px 3px rgba(0, 0, 0, 0.5)",
                // transition: "transform 0.3s"
                transition: "300ms ease, 300ms ease transform",
                transform: `translateY(-1px)`,
              }
            }}
          >
            <Box 
              display="flex"
              alignItems="center"
            >
              <ArticleIcon color="secondary" />
              <Typography 
                variant="body2"
                color="primary"
                pl={1}
              >
                {name}
              </Typography>
            </Box>
            <Box
              display="flex"
            >
              <Typography 
                variant="body2"
                color="primary"
              >
                <code>{size}</code>
              </Typography>
              <Typography variant="caption">
                <code>MB</code>
              </Typography>
            </Box>
            
          </Box>
        ))}          
      </Stack>
    </Box>
  );
};