import React from 'react';
import { Box } from '@mui/material';
import { SearchBar, Section } from 'src/components';

import Listing from './Listing';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Section 
      height="auto"
      p={0}
      pr={2}
    >
      {/* <Container sx={{padding:0}}> */}
        <Box p={1} />

        <SearchBar />

        <Listing />
      {/* </Container> */}
    </Section>
  );
};