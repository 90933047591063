// aitibot.ai

import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { 
  Alert, 
  Box,
  Checkbox,
  CircularProgress, 
  FormHelperText,
  Grid, 
  Link,
  MenuItem,
  Typography,
} from '@mui/material';

import { LargeButton, Pill, Subheader, TextField } from 'src/components';
import { StateContext } from 'src/contexts';
import { handleSubmit } from './handleSubmit';

const packages = [
  "Basic",
  "Standard",
  "Professional"
];

// const amount = {
//   "Basic": 2,
//   "Standard": 3,
//   "Professional": 100
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { services } = useContext(StateContext);
  
  return (
    <Formik
      initialValues={{
        name: "",
        surname: "",
        email: "",
        mobile: "",
        package: "",
        password: "",
        password_confirmation: "",
        agree: false,
        serverValidationErrors: false
      }}
      validationSchema={
        Yup.object().shape({
          name: Yup.string().max(255).required('Your name is required'),
          surname: Yup.string().max(255).required('Your surname is required'),
          email: Yup.string().email('Must be a valid email').max(255).required('Your email is required'),
          mobile: Yup.string().max(255).required("Your mobile number is required"),
          package: Yup.string().max(1000).required('Select the package you want to subscribe to'),
          password: Yup.string().max(255).required('Password is required'),
          password_confirmation: Yup.string().max(255).required('Password confirmation is required'),
          agree: Yup.boolean().oneOf([true], 'This field must be checked to indicate you have agreed to the Ts & Cs'),
        })
      }
      onSubmit={async (values, actions) => {
        // data 
        await handleSubmit(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        // setValues,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid 
            container
            spacing={1}
          >
            <Grid item sm={12} xs={12}>
              <Subheader pt={2}>Personal Information</Subheader>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                // size="small"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="First name"
                margin="none"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                // size="small"
                error={Boolean(touched.surname && errors.surname)}
                fullWidth
                helperText={touched.surname && errors.surname}
                label="Surname"
                margin="none"
                name="surname"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.surname}
                variant="outlined"
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                // size="small"
                error={Boolean(touched.mobile && errors.mobile)}
                fullWidth
                helperText={touched.mobile && errors.mobile}
                label="Mobile"
                placeholder="Your mobile number e.g. 0773 123 456"
                margin="none"
                name="mobile"
                onBlur={handleBlur}
                onChange={handleChange}
                type="mobile"
                value={values.mobile}
                variant="outlined"
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Subheader pt={2}>Select Your Package</Subheader>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                select
                fullWidth={true}
                name="package"
                label="Package"
                margin="none"
                type="text"
                variant="outlined"
                value={values.package}
                onChange={handleChange}
                error={Boolean(errors.package)}
                helperText={Boolean(errors.package) && errors.package}
              >
                {packages.map((p, i) => <MenuItem key={i} value={p}>{p}</MenuItem>)}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Box 
                sx={{
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >
                {services.slice(0, 100).map(({icon, title}, i) => (
                  <Pill 
                    key={i}
                    icon={icon}
                    label={title} 
                  />
                ))}
              </Box>
            </Grid>

            <Grid item sm={12} xs={12}>
              <Subheader pt={2}>Account Information</Subheader>
            </Grid>
            
            <Grid item sm={12} xs={12}>
              <TextField
                // size="small"
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email address"
                placeholder="This email will be used as your email"
                margin="none"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="none"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                fullWidth
                helperText={touched.password_confirmation && errors.password_confirmation}
                label="Confirm password"
                placeholder="Repeat the password you entered above"
                margin="none"
                name="password_confirmation"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password_confirmation}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                alignItems="center"
                display="flex"
                ml={-1}
              >
                <Checkbox
                  checked={values.agree}
                  name="agree"
                  onChange={handleChange}
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  I have read the
                  {' '}
                  <Link
                    color="primary"
                    component={RouterLink}
                    to="#"
                    underline="always"
                    variant="h6"
                  >
                    Terms and Conditions
                  </Link>
                </Typography>
              </Box>
              {Boolean(touched.agree && errors.agree) && (
                <FormHelperText error>
                  {errors.agree}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box>
                {status && (
                  <Alert severity={values.serverValidationErrors ? "error" : "success"}>
                    { status }
                  </Alert>
                )}

                {(Object.keys(errors).length > 0) && (
                  <Alert severity="error">
                    Some fields in the form need your attention
                  </Alert>
                )}
              </Box>

              <Box>
                <LargeButton
                  // sx={{padding:4}} 
                  color="primary"
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress color="secondary" size={20} thickness={8} /> : null}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting ? "Processing..." : "Submit Application"}
                </LargeButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
};