import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const Page = forwardRef(({children, title, color, description, ...rest}, ref) => {
  return (
    <Box
      ref={ref}
      sx={{backgroundColor: color}}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content={color} />
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string
};

Page.defaultProps = {
  title: '',
  description: ''
};

export default Page;
