import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from "@mui/material";

// import { makeStyles } from '@mui/styles';
// import { useCache } from 'src/hooks';

import TopBar from './TopBar';
import NavBar from './NavBar';
import DialogBox from './DialogBox';

import theme from "src/theme";

const styles = {
  root: {
    
  },
  wrapper: {
    
  },
  contentContainer: {
    
  },
  content: {
    
  }
};

const Layout = ({children}) => {
  // const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  
  return (
    <Box 
      display='flex'
      backgroundColor={({palette}) => palette.background.light}
      height='100%'
      width='100%'
      sx={{overflow:'hidden'}}
    >
      <TopBar 
        notifications={[]}
        onMobileNavOpen={() => setMobileNavOpen(true)} 
      />

      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      
      {/* <DialogBox /> */}
      
      <Box 
        display='flex'
        flex='1 1 auto'
        pt={"64px"}
        pl={{xs:0, md:"256px"}}
        sx={{overflow:'hidden'}}
      >
        <Box 
          display='flex'
          flex='1 1 auto'
          sx={{overflow:'hidden'}}
        >
          <Box 
            flex='1 1 auto'
            height='100%'
            sx={{overflow:'auto'}}
            // border="10px red solid" 
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
