import React from 'react';
import { Stack } from '@mui/material';


import Menu from "./Menu";
import Downloads from './Downloads';
import Banner from './Banner';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Stack display={{xs:"none", md:"block"}}>
      <Menu/>
      <Downloads />
      <Banner />
    </Stack>
  );
};