import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { LargeButton } from "src/components";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Box
      backgroundColor="#364f6b"
      marginTop={5}
      marginBottom={10}      
    >      

      <Container>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box display="flex">
              <img alt="Kurai Medical Services" src="/images/about-services.jpg" width={"100%"}/>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} p={{xs:"20px 0", md: 5}}>
            <Stack
              justifyContent="center"
              height={"100%"}
              // pl={{xs:0, md:5}}
              // p={{xs:0, md:0}}
              // border="1px aqua solid"
              alignItems={{xs:"center", md:"flex-start"}}
              textAlign={{xs:"center", md:"left"}}
            >
              <Typography variant="h5" color="secondary">SERVICES</Typography>
              <Typography 
                mt={1} 
                variant="h1"
                color="white" 
                fontWeight="bold"
              >
                Choose from our product range
              </Typography>
              <Typography mt={2} mb={2} variant="body2">
                Ut commodo pulvinar veritatis occaecati mauris, tristique tristique turpis 
                pharetra beatae litora donec pharetra, recusandae, volutpat lectus, ips 
                sapiente natoque etiam
              </Typography>

              <Box width={250}>
                <LargeButton
                  fullWidth
                  variant="outlined"
                  endIcon={<ArrowForward />}
                  href="/services"
                  sx={{border: "1px white solid", color: "white"}}
                >
                  Find out more
                </LargeButton>

              </Box>

            </Stack>
          </Grid>
        </Grid>

      </Container>
    
    </Box>
  );
};