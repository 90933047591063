const { Tween } = require("react-gsap");

const FadeInRight = ({ children }) => (
  <Tween
    from={{ opacity: 0, transform: 'translate3d(0, 0, 100vw)' }}
    ease="back.out(1.4)"
  >
    {children}
  </Tween>
);

export default FadeInRight;