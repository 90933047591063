import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Banner } from "src/components";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {  
  return (
    <Banner
      height={300}
      bgPosY="13%"
      background="/images/services.jpg"
      title={
        <Container maxWidth="sm">
          <Box 
            width={{xs:"100%", md :"100%"}}
            // color={({palette}) => palette.primary.main}
          >
            <Typography variant="h1">Welcome</Typography>
            <Typography>
              Donec velit. Mauris massa. Vestibulum non nulla. Nam suscipit arcu 
              nec elit. Phasellus sollicitudin iaculis ante.
            </Typography>
          </Box>
        </Container>
    }
    />
    
  );
};