import { createBreakpoints } from '@mui/system';
import AvenirBlack from './fonts/AvenirLTProBlack.otf';
import AvenirLight from './fonts/AvenirLTProLight.otf';
import AvenirMedium from './fonts/AvenirLTProMedium.otf';
// import AvenirStandard from './fonts/AvenirLTStd-Roman.otf';
import AvenirUltraLight from './fonts/AvenirNextLTPro-UltLt.ttf';

const breakpoints = createBreakpoints({})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Avenir',
          font-weight: 400,
          src: local('Avenir'), local('Avenir-Regular'), url(${AvenirMedium}, format('opentype'))
        }
        @font-face {
          font-family: 'Avenir',
          font-weight: 900,
          src: local('Avenir'), local('Avenir-Black'), url(${AvenirBlack}, format('opentype'))
        }
        @font-face {
          font-family: 'Avenir',
          src: local('Avenir'), local('Avenir-Light'), url(${AvenirLight}, format('opentype'))
        }
        @font-face {
          font-family: 'Avenir',
          src: local('Avenir'), local('Avenir-Ultra-Light'), url(${AvenirUltraLight}, format('opentype'))
        }
      `
    }
  },
  fontFamily: 'Avenir',
  menu: {
    fontSize: 14.5,
    letterSpacing: 1.5,
    // fontWeight: "bold", 
    fontFamily: "Avenir",
    color: "white",
    textAlign: "center"
  },
  h1: {
    fontFamily: "Avenir-Black",
    fontWeight: 900,
    fontSize: 48,
    opacity: 0.75,
    // color: "#104E21",
    letterSpacing: '-0.24px',
    [breakpoints.down("sm")]: {
      fontSize: 23.5,
      fontWeight: 900,
      // color: "red"
    }
  },
  h2: {
    fontWeight: 500,
    fontSize: 46,
    // color: "#104E21",
    letterSpacing: '-0.24px',
    [breakpoints.down("sm")]: {
      fontSize: 20,
      fontWeight: 900,
      // color: "red"
    }
  },
  h3: {
    fontWeight: 900,
    fontSize: 30,
    letterSpacing: '-0.06px',
    [breakpoints.down("sm")]: {
      fontSize: 20
    }
  },
  h4: {
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: '-0.06px',
    [breakpoints.down("sm")]: {
      fontSize: 18,
      fontWeight: 900,
      // color: "red"
    }
  },
  h5: {
    fontSize: 18,
    letterSpacing: '-0.05px',
    fontWeight: 900,
    [breakpoints.down("sm")]: {
      fontSize: 10
    }
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px'  
  },
  p: {
    display: "block",
    fontFamily: "Avenir",
    fontSize: 18,
    marginBottom: 20,
    [breakpoints.down("sm")]: {
      fontSize: 12,
      // color: "blue"
    }
  },
  body1: {
    fontSize: 18,
    [breakpoints.down("sm")]: {
      fontSize: 12,
      // color: "blue"
    }
  },
  body2: {
    fontSize: 16,
    [breakpoints.down("sm")]: {
      fontSize: 10
    }
  },
  body3: {
    fontSize: 12,
    fontFamily: "Avenir",
    [breakpoints.down("sm")]: {
      fontSize: 9
    }
  },
  large: {
    fontSize: 72,
    fontFamily: "Avenir",
    [breakpoints.down("sm")]: {
      fontSize: 9
    }
  },
  overline: {
    fontWeight: 500
  },
  list: {
    fontSize: 18,
    fontFamily: "Avenir",
    marginLeft: 4,
    [breakpoints.down("sm")]: {
      fontSize: 12,
      // color: "blue"
    }
  },
};
