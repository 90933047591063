import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Hidden, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import { Logo } from 'src/components';

import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';

const items = [
  { icon: <CallIcon color="primary" />, title: "CONTACT NUMBER", desc: "(+263) 27899123980" },
  { icon: <EmailIcon color="primary" />, title: "EMAIL ADDRESS", desc: "info@kuraimedical.co.zw" },
  { icon: <LocationOnIcon color="primary" />, title: "BUSINESS ADDRESS", desc: "1st Floor Kukunda Hse, Harare" },
];

const TopBar = ({ onMobileNavOpen }) => {
  const { palette } = useTheme();

  return (
    <Container>
      <Box display="flex">
        <Box width={{xs: 200, md: 350}}>
          <Logo width={"100%"} fill={palette.primary.main} />
        </Box>
          
        <Box sx={{flexGrow: 1}} />
        
        <Hidden mdDown>
          <Box 
            display="flex"
            alignItems="center"
          >
            {items.map(({icon, title, desc}, i) => (
              <Box 
                key={i} 
                display="flex"
                pr={3}
              >
                <Box pr={0.3} display="flex" alignItems="center">
                  {icon}
                </Box>
                <Box display="flex" alignItems="center">
                  <Tooltip title={title} placement="top">
                    <Typography variant="body3" lineHeight={0}>
                      {desc}
                    </Typography>
                  </Tooltip>
                </Box>
              
              </Box>
            ))}
          </Box>
        </Hidden>

        
        <Hidden mdUp>
          <IconButton
            color="inherit"
            href="/login"
          >
            <PersonIcon />
          </IconButton>

          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        
      </Box>
    </Container>
  ); 
};

// const styles = {
//   list: {
//     display: "flex", 
//     listStyle: "none", 
//     margin: 0,
//     padding: 0,
//     border: "1px pink solid"
//   },
//   listItems: {
//     // border: "1px pink solid"/
//   },
//   links: {
//     color: "white",
//     textDecoration: "none"
//   }
// };

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
