const data = {
  filter: [
    "Places",
    "Regions", 
    "Categories",
  ],
  groups: {
    services: [
      {
        name: "All Services",
        list: [
          "Ambulance Services",
          "Maternity",
          "Opticians",
          "Dental Clinics",
          "Senior Citizen",
        ]
      }
    ],
    regions: [
      
    ], 
    categories: [
      
    ],
  },
  regions: [
    {
      name: "Harare",
      coords: "",
      places: [
        "Harare"
      ],
    },
    {
      name: "Bulawayo",
      coords: "",
      places: [
        "Bulawayo"
      ],
    },
    {
      name: "Manicaland",
      coords: "",
      places: [
        "Buhera",
        "Chimanimani",
        "Chipinge",
        "Makoni",
        "Mutare",
        "Mutasa",
        "Nyanga",
      ],
    },
    {
      name: "Mashonaland Central",
      coords: "",
      places: [
        "Bindura",
        "Guruve",
        "Mazowe",
        "Mbire",
        "Mount Darwin",
        "Muzarabani",
        "Mukumbura",
        "Rushinga",
        "Shamva",
      ],
    },
    {
      name: "Mashonaland East",
      coords: "",
      places: [
        "Marondera",
        "Goromonzi",
        "Ruwa",
        "Chikomba",
        "Mudzi",
        "Murehwa",
        "Mutoko",
        "Seke",
        "Hwedza",
        "UMP (Uzumba-Maramba-Pfungwe)",
      ],
    },
    {
      name: "Mashonaland West",
      coords: "",
      places: [
        "Chegutu",
        "Hurungwe",
        "Kariba",
        "Makonde",
        "Mhondoro-Ngezi",
        "Sanyati",
        "Zvimba",
        "Kadoma",
      ],
    },
    {
      name: "Masvingo",
      coords: "",
      places: [
        "Bikita",
        "Chiredzi",
        "Chivi",
        "Gutu",
        "Masvingo",
        "Mwenezi",
        "Zaka",
      ],
    },
    {
      name: "Matabeleland North",
      coords: "",
      places: [
        "Binga",
        "Bubi",
        "Hwange",
        "Lupane",
        "Nkayi",
        "Tsholotsho",
        "Umguza",
      ],
    },
    {
      name: "Matabeleland South",
      coords: "",
      places: [
        "Beitbridge",
        "Bulilima",
        "Gwanda",
        "Insiza",
        "Mangwe",
        "Matobo",
        "Umzingwane",
      ],
    },
    {
      name: "Midlands",
      coords: "",
      places: [
        "Chirumhanzu",
        "Gokwe North",
        "Gokwe South",
        "Gweru",
        "Kwekwe",
        "Mberengwa",
        "Shurugwi",
        "Zvishavane",
      ],
    },
  ],
  categories: [
    {
      name: "Places",
      list: [
        "Hospitals",
        "Pharmacies",
        "Dentists",
        "General"
      ],
    },
    {
      name: "Allergy and Immunology",
      list: [
        

      ]
    },
    {
      name: "Anesthesiology",
      list: [
        "Critical care medicine",
        "Hospice and palliative care",
        "Pain medicine",
        "Pediatric anesthesiology",
        "Sleep medicine",
      ]
    },
    {
      name: "Dermatology",
      list: [
        "Dermatopathology",
        "Pediatric dermatology",
        "Procedural dermatology",

      ]
    },
    {
      name: "Diagnostic radiology",
      list: [
        "Abdominal radiology",
        "Breast imaging",
        "Cardiothoracic radiology",
        "Cardiovascular radiology",
        "Chest radiology",
        "Emergency radiology",
        "Endovascular surgical neuroradiology",
        "Gastrointestinal radiology",
        "Genitourinary radiology",
        "Head and neck radiology",
        "Interventional radiology",
        "Musculoskeletal radiology",
        "Neuroradiology",
        "Nuclear radiology",
        "Pediatric radiology",
        "Radiation oncology",
        "Vascular and interventional radiology",
      ]
    },
    {
      name: "Emergency medicine",
      list: [
        "Anesthesiology critical care medicine",
        "Emergency medical services",
        "Hospice and palliative medicine",
        "Internal medicine / Critical care medicine",
        "Medical toxicology",
        "Pain medicine",
        "Pediatric emergency medicine",
        "Sports medicine",
        "Undersea and hyperbaric medicine",
      ]
    },
    {
      name: "Family medicine",
      list: [
        "Adolescent medicine",
        "Geriatric medicine",
        "Hospice and palliative medicine",
        "Pain medicine",
        "Sleep medicine",
        "Sports medicine", 
      ]
    },
    {
      name: "Internal medicine",
      list: [
        "Advanced heart failure and transplant cardiology",
        "Cardiovascular disease",
        "Clinical cardiac electrophysiology",
        "Critical care medicine",
        "Endocrinology, diabetes, and metabolism",
        "Gastroenterology",
        "Geriatric medicine",
        "Hematology",
        "Hematology and oncology",
        "Infectious disease",
        "Internal medicine",
        "Interventional cardiology",
        "Nephrology",
        "Oncology",
        "Pediatric internal medicine",
        "Pulmonary disease",
        "Pulmonary disease and critical care medicine",
        "Rheumatology",
        "Sleep medicine",
        "Sports medicine",
        "Transplant hepatology", 
      ]
    },
    {
      name: "Medical genetics",
      list: [
        "Biochemical genetics",
        "Clinical cytogenetics",
        "Clinical genetics",
        "Molecular genetic pathology",
      ]
    },
    {
      name: "Neurology",
      list: [
        "Brain injury medicine",
        "Child neurology",
        "Clinical neurophysiology",
        "Endovascular surgical neuroradiology",
        "Hospice and palliative medicine",
        "Neurodevelopmental disabilities",
        "Neuromuscular medicine",
        "Pain medicine",
        "Sleep medicine",
        "Vascular neurology",
      ]
    },
    {
      name: "Obstetrics and gynecology",
      list: [
        "Female pelvic medicine and reconstructive surgery",
        "Gynecologic oncology",
        "Maternal-fetal medicine",
        "Reproductive endocrinologists and infertility",  
      ]
    },
    {
      name: "Pathology",
      list: [
        "Anatomical pathology",
        "Blood banking and transfusion medicine",
        "Chemical pathology",
        "Clinical pathology",
        "Cytopathology",
        "Forensic pathology",
        "Genetic pathology",
        "Hematology",
        "Immunopathology",
        "Medical microbiology",
        "Molecular pathology",
        "Neuropathology",
        "Pediatric pathology",
      ]
    },
    {
      name: "Pediatrics",
      list: [
        "Adolescent medicine",
        "Child abuse pediatrics",
        "Developmental-behavioral pediatrics",
        "Neonatal-perinatal medicine",
        "Pediatric cardiology",
        "Pediatric critical care medicine",
        "Pediatric endocrinology",
        "Pediatric gastroenterology",
        "Pediatric hematology-oncology",
        "Pediatric infectious diseases",
        "Pediatric nephrology",
        "Pediatric pulmonology",
        "Pediatric rheumatology",
        "Pediatric sports medicine",
        "Pediatric transplant hepatology",
      ]
    },
    {
      name: "Physical medicine and Rehabilitation",
      list: [
        "Brain injury medicine",
        "Hospice and palliative medicine",
        "Neuromuscular medicine",
        "Pain medicine",
        "Pediatric rehabilitation medicine",
        "Spinal cord injury medicine",
        "Sports medicine",
      ]
    },
    {
      name: "Preventive medicine",
      list: [
        "Brain injury medicine",
        "Hospice and palliative medicine",
        "Neuromuscular medicine",
        "Pain medicine",
        "Pediatric rehabilitation medicine",
        "Spinal cord injury medicine",
        "Sports medicine",
      ]
    },
    {
      name: "Psychiatry",
      list: [
        "Addiction psychiatry",
        "Administrative psychiatry",
        "Child and adolescent psychiatry",
        "Community psychiatry",
        "Consultation/liaison psychiatry",
        "Emergency psychiatry",
        "Forensic psychiatry",
        "Geriatric psychiatry",
        "Mental retardation psychiatry",
        "Military psychiatry",
        "Pain medicine",
        "Psychiatric research",
        "Psychosomatic medicine",
      ]
    },
    {
      name: "Radiation oncology",
      list: [
        "Hospice and palliative medicine",
        "Pain medicine",
      ]
    },
    {
      name: "Surgery",
      list: [
        "Colon and rectal surgery",
        "General surgery",
        "Gynecologic oncology",
        "Plastic surgery",
        "Hand surgery",
        "Endovascular surgical neuroradiology",
        "Orthopaedic surgery",
        "Adult reconstructive orthopaedics",
        "Foot and ankle orthopaedics",
        "Musculoskeletal oncology",
        "Orthopaedic sports medicine",
        "Orthopaedic surgery of the spine",
        "Orthopaedic trauma",
        "Pediatric orthopaedics",
        "Pediatric otolaryngology",
        "Otology neurotology",
        "Pediatric surgery",
        "Neonatal",
        "Prenatal",
        "Trauma",
        "Pediatric oncology",
        "Surgical Intensivists, specializing in critical care patients",
        "Thoracic Surgery",
        "Vascular surgery",
      ]
    }
  ],
  places: [
    {
      name: "Hospitals",
      list: [
        "Pari",
        "Harare",
      ]
    },
    { 
      name: "Clinics", 
      list: []
    },
    { 
      name: "Pharmacies", 
      list: []
    },
    { 
      name: "Labs", 
      list: []
    },
    { 
      name: "Rehabilitation", 
      list: []
    },
    { 
      name: "Surgery", 
      list: []
    },
  ]
};

export default data;