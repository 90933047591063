import React from 'react';
import { 
  Box, 
  Stack,
  // Card,
  // CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography
 } from '@mui/material';
import { ReactComponent as AvatarPlaceholder } from "src/svg/avatar-temp.svg";

const user = {
  username: "j.kudu@test.co.zw",
  role: "member",
  token: "798620360293097309273097",
  name: "James Kudu",
  // avatar: null,
};

const profile = {
  title: "Mr",
  gender: "Male",
  mobile: "0771 123 456",
  landline: "(04) 789 898",
  address: "34 Dura Street, New Town, Harare",
  emergencyContact: "Mrs Sylvia Hondo",
  emergencyMobile: "988 000 880",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Box 
      display="flex"
      justifyContent="center"
      // border="1px red solid"
    >
      <Stack width={{md:500, xs: "90%"}}>
        <Box display="flex" justifyContent="center" p={2}>
          <AvatarPlaceholder width="70%" />
        </Box>
        <Box>
          <Box p={2} color="white" backgroundColor={({palette}) => palette.background.dark} textAlign="center">
            <Typography>Account Information</Typography>
          </Box>
          <List>
            {Object.keys(user).map((key, i) => (     
              <ListItem
                key={i} 
                alignItems="flex-start"
              >
                <ListItemText primary={key} />
                
                <ListItemSecondaryAction>
                  <Typography variant="body2">{user[key]}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box>
          <Box p={2} color="white" backgroundColor={({palette}) => palette.background.dark} textAlign="center">
            <Typography>Profile Information</Typography>
          </Box>
          <List>
            {Object.keys(profile).map((key, i) => (     
              <ListItem
                key={i} 
                alignItems="flex-start"
              >
                <ListItemText primary={key} />
                
                <ListItemSecondaryAction>
                  <Typography variant="body2">{profile[key]}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
        

      </Stack>
    </Box>
  );
};