import axios from 'axios';
import { urls } from 'src/common';

export function handleSubmit(values, actions) 
{  
  axios.post(`${urls.baseURL}/feedback`, values).then((response) => {
    const result = response.data;
    
    if (result.success) {  
      actions.resetForm({"serverValidationErrors": false});
      actions.setStatus("Your feedback has been submitted to us. Thank you for your time!");
    }
    else {
      actions.setFieldValue("serverValidationErrors", true);
      actions.setStatus("You have invalid entries");

      Object.keys(result.data).map(field => actions.setFieldError(field, result.data[field][0]));
    }
  })
  .catch(error => {
    actions.setFieldValue("serverValidationErrors", true);
    actions.setStatus(`Your ${values.type} cannot be accepted at the moment. Try again in a few minutes`);
  })
  .finally(() => {
    actions.setSubmitting(false); 
  });
}