import React from "react";
import { Box, Button, Container, Grid, Stack, Typography, useTheme } from "@mui/material";

const packages = [
  {
    image: "/images/package-1.jpg",
    title: "Basic",
    price: "$20",
    link: "/#",
    items: [
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet"
    ]
  },
  {
    image: "/images/package-2.jpg",
    title: "Standard",
    price: "$45",
    link: "/#",
    items: [
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
    ]
  },
  {
    image: "/images/package-3.jpg",
    title: "Pro",
    price: "$80",
    link: "/#",
    items: [
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",

    ]
  }
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { palette } = useTheme();

  return (
    <Box
      // backgroundColor="#364f6b"
      marginTop={5}
      // padding={20}
      // color="white"
      textAlign="center"
    >
      <Container>
        <Grid container spacing={3}>
          {packages.map((s, i) => (
            <Grid item key={i} xs={12} md={4} style={styles.item}>
              <Box
                display="flex"
                flexDirection="column"
                height={"100%"}
                alignContent="stretch"
                sx={{":hover": {
                  boxShadow: "0px 0px 25px 3px rgba(0, 0, 0, 0.5)",
                  // transition: "transform 0.3s"
                  transition: "300ms ease, 300ms ease transform",
                  transform: `translateY(-1px)`,
                }}}
                border="1px lightGray solid"
              >
                <Box p={2}>
                  <img alt={s.title} src={s.image} />
                </Box>

                <Box 
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                  color="white"
                  backgroundColor={palette.primary.main}
                >
                  <Typography variant="h3">{s.title}</Typography>
                  <Typography variant="h3">{s.price}</Typography>
                </Box>

                <Stack mt={3}>
                  {s.items.map((item, i) => (<Typography key={i} mb={0.8} color="gray">{item}</Typography>))}
                </Stack>
                
                <Box flexGrow={1} />

                <Box mt={3} mb={2} pl={2} pr={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{padding:1.7, borderRadius:0}}
                  >
                    Subscribe
                  </Button>
                </Box>
              </Box>
          </Grid>))}
        </Grid>

        <Box p={3} />
      </Container>
    </Box>
  );
};

const styles = {
  item: {
    // flex: "0 0 0px",
    paddingBottom: 70,
    // border: "1px white solid",
    textAlign: "center",
    // borderRadius: "50%",
  }
};