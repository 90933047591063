import React from 'react';
import { Stack, Typography } from "@mui/material";


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Stack justifyContent={"center"}>
      <Typography variant="h2" mt={1}>Lorem quis bibendum auctor</Typography>
      <br />
      <Typography variant="p">
        Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, 
        nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus 
        a sit amet mauris. Morbi accumsan ipsum velit.
      </Typography>
      {/* <Box>
        <LargeButton
          component="a"
          startIcon={<PhoneForwardedIcon />}
          variant="contained"
          onClick={() => document.querySelector("#request-form").scrollIntoView({ behavior: 'smooth' })}
        >
          Request a call
        </LargeButton>
      </Box> */}
    </Stack>
  );
};