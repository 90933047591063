import React from "react";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { StateContext } from "src/contexts";
import { ArrowForward } from "@mui/icons-material";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { services } = React.useContext(StateContext);

  return (
    <Box
      // backgroundColor="#364f6b"
      mt={5}
      // padding={20}
      // color="white"
      textAlign="center"
    >
      <Container>
        {/* <Box p={5}>
          <Typography variant="h1">Other Services</Typography>
          <Typography>Other service and product offerings include:</Typography>
        </Box> */}
        
        <Grid container spacing={5}>
          {services.map((s, i) => (
            <Grid key={i} item xs={12} sm={6} mb={{xs:3, md:0}} style={styles.item}>
            <Stack 
              m={0}
              direction={{xs:"column", md:"row" }}
              spacing={{xs:0, sm:1, md:2}}
              justifyContent="center"
              backgroundColor={({palette}) => palette.background.light}
            >
              <Box
                width="100%"
                display="flex"
                alignItems="center"
              >
                <img alt={s.title} src={s.image} width="100%" />
              </Box>
              <Stack
                pl={2}
                pr={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                // border="1px blue solid"
              >
                <Box mt={{xs:1, sm:0}}>
                  <Typography variant="h4">{s.title}</Typography>
                  <Typography variant="body2">{s.desc}</Typography>
                </Box>

                <Box ml={0} pb={{xs:1, md:0}}>
                  <Button
                    size="small"
                    endIcon={<ArrowForward />}
                    href={s.url}
                  >
                    Find out more
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Grid>))}
        </Grid>
      </Container>
    </Box>
  );
};

const styles = {
  item: {
    // flex: "0 0 0px",
    // paddingBottom: 70,
    // border: "1px green solid",
    textAlign: "left",
    // borderRadius: "50%",
  }
};