import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
import { StateContext } from 'src/contexts';

import TopBar from './TopBar';
import NavBar from './NavBar';
import Sticky from './StickHeader';
import Footer from './Footer';

const MainLayout = ({children}) => {
  // // const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);
  const { backgroundColor } = useContext(StateContext);

  return (
    <div style={styles.root}>
      <TopBar 
        // backgroundColor={backgroundColor}
        // height={topBarHeight}
        onMobileNavOpen={() => setMobileNavOpen(true)}
      />
      
      <Sticky 
        sections={[]}
        backgroundColor={backgroundColor}
      />
      
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        backgroundColor={backgroundColor} 
      />

      <div style={styles.wrapper}>
        <div style={styles.container}>
          <div style={styles.content}>
            <Outlet />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const styles = {
  root: {
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // paddingTop: 200,
    marginTop: -57
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden'
  }
};

export default MainLayout;
