import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Section } from "src/components";

// const company = [
//   {
//     title: "Our Mission", 
//     desc: "Eos nam iste mi leo nihil posuere, vehicula iste dolore, doloremque vestibulum, reprehen derit.",
//     color: "#1F85B5",
//     icon: "/images/about-mission.png"
//   },
//   {
//     title: "Our Vision", 
//     desc: "Eos nam iste mi leo nihil posuere, vehicula iste dolore, doloremque vestibulum, reprehen derit.",
//     color: "#940092",
//     icon: "/images/about-vision.png"
//   },
//   {
//     title: "Our Value", 
//     desc: "Eos nam iste mi leo nihil posuere, vehicula iste dolore, doloremque vestibulum, reprehen derit.",
//     color: "#C04700",
//     icon: "/images/about-value.png"
//   },
// ];

const About = () => {
  return (
    <Section
      id="About"
      height="auto"
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Stack 
              justifyContent="center"
              // border="1px red solid"
              height="100%"
            >
              <Typography variant="h5">JOIN OUR FAMILY</Typography>
              <Typography variant="h2" mb={2}>
                Select the right package for you
              </Typography>
              <Typography variant="p">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, 
                nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus 
                a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt 
                auctor a ornare odio. Sed non mauris vitae erat consequat. 
              </Typography>
              <Typography variant="p">
                Donec velit. Mauris massa. Vestibulum non nulla. Nam suscipit arcu nec elit. 
                Phasellus sollicitudin iaculis ante. Ut non mauris et sapien tincidunt adipiscing.
                Vestibulum vitae leo. Suspendisse nec mi tristique nulla laoreet vulputate.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box 
              display="flex" 
              justifyContent="center"
            >
              <img alt="Quality Medical Aid" src="/images/request-call.png" />
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={12}>  
            <Box mt={6} />
            
            <Grid container spacing={3}>
              {company.map(({title, desc, color, icon}, i) => (
                <Grid key={i} item xs={12} sm={4}>
                  <Box
                    backgroundColor={color}
                    color="white"
                    p={3}
                    textAlign="center"
                  >
                    <Box p={3}>
                      <img alt={title} src={icon} width={64} />
                    </Box>
                    <Typography variant="h3">{title}</Typography>
                    <Typography>{desc}</Typography>
                  </Box>
                </Grid>  
              ))}

            </Grid>

          </Grid> */}
        </Grid>
        


        {/* <Box>
              

              <Typography variant="h2">Our Mission</Typography>
              <Typography>
                Kurai Medical provides the general business community of Southern, 
                Western &amp; Eastern Africa with a unique and dynamic platform for both private 
                and public business leaders and executives to network and develop skills/knowledge 
                in the business arena.
              </Typography>
              <Typography>
                We aim to provide creative business solutions for local and international 
                enterprises through the promotion of global opportunities for our clients to 
                profit from, through interaction and learning.
              </Typography>

              <Typography variant="h2">Our Services</Typography>
              <Typography>
                Our service and product offering include: conferences, corporate sponsorship 
                and hospitality, exhibitions and tailored corporate training.
              </Typography>

              <LargeButton
                component="a"
                variant="contained"
                href="/services"
              >
                Find Out More
              </LargeButton>
            </Box> */}
        
      </Container>
    </Section>
  );
};

export default About;