// aitibot.ai

import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Box, CircularProgress, Container, Grid, TextField, Typography } from '@mui/material';
import { LargeButton, Section} from 'src/components';
import { handleSubmit } from './handleSubmit';

// const topics = [
//   "Product related",
//   "Service related",
//   "Customer care",
//   "Report abuse",
//   "Other"
// ];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Section 
      id="contact-form"
      component="section"
      justifyContent="flex-end"
      // p={{xs:"35px 0px", md: "50px 225px"}}
      p={0}
      // width="100%"
      height="auto"
      // backgroundColor="white"
    >
      <Container sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <Box width={{xs:"100%"}}>
          <Typography variant="h5" pb={2}>Contact Form</Typography>
          {/* <Box mt={3}>
            <Subheader variant="h4">
              Your Contact Information
            </Subheader>
          </Box> */}

          {/* <Divider sx={{margin:"5px 0"}} /> */}

          <Formik
            initialValues={{
              type: "inquiry",
              topic: "",
              name: "",
              surname: "",
              email: "",
              mobile: "",
              description: "",
              serverValidationErrors: false
            }}
            validationSchema={
              Yup.object().shape({
                topic: Yup.string().max(255).required('Select an option'),
                name: Yup.string().max(255).required('Your name is required'),
                surname: Yup.string().max(255).required('Your surname is required'),
                email: Yup.string().email('Must be a valid email').max(255).required('Your email is required'),
                mobile: Yup.string().max(255),
                description: Yup.string().max(1000).required('The description is required'),
              })
            }
            onSubmit={async (values, actions) => {
              // data 
              await handleSubmit(values, actions);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              // setValues,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid 
                  container
                  spacing={1}
                >
                  <Grid item sm={6} xs={12}>
                    <TextField
                      size="small"
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      margin="none"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      size="small"
                      error={Boolean(touched.surname && errors.surname)}
                      fullWidth
                      helperText={touched.surname && errors.surname}
                      label="Surname"
                      margin="none"
                      name="surname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.surname}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      size="small"
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email address"
                      margin="none"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      size="small"
                      error={Boolean(touched.mobile && errors.mobile)}
                      fullWidth
                      helperText={touched.mobile && errors.mobile}
                      label="Mobile (optional)"
                      margin="none"
                      name="mobile"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="mobile"
                      value={values.mobile}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box mb={3}>
                      
                      {/* <Subheader variant="h4" gutterBottom>
                        Provide more information
                      </Subheader> */}
                      <Typography variant="body2" gutterBottom>
                        Give us a brief description of what you would like to tell us.
                      </Typography>
                      {/* <Typography variant="body2" gutterBottom>
                        We will do our best to respond in 24 hours.
                      </Typography> */}
                    </Box>
                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      name="description"
                      label="Description"
                      rows={3}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      {status && (
                        <Alert severity={values.serverValidationErrors ? "error" : "success"}>
                          { status }
                        </Alert>
                      )}

                      {(Object.keys(errors).length > 0) && (
                        <Alert severity="error">
                          Some fields in the form need your attention
                        </Alert>
                      )}
                    </Box>

                    <Box>
                      <LargeButton
                        // sx={{padding:4}} 
                        color="primary"
                        disabled={isSubmitting}
                        endIcon={isSubmitting ? <CircularProgress color="secondary" size={20} thickness={8} /> : null}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {isSubmitting ? "Processing..." : "Send Message"}
                      </LargeButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Container>
    </Section>
  )
};
