import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { LargeButton, Section } from "src/components";

const company = [
  {
    title: "Our Mission", 
    desc: "Eos nam iste mi leo nihil posuere, vehicula iste dolore, doloremque vestibulum, reprehen derit.",
    color: "#1F85B5",
    icon: "/images/about-mission.png"
  },
  {
    title: "Our Vision", 
    desc: "Eos nam iste mi leo nihil posuere, vehicula iste dolore, doloremque vestibulum, reprehen derit.",
    color: "#940092",
    icon: "/images/about-vision.png"
  },
  {
    title: "Our Value", 
    desc: "Eos nam iste mi leo nihil posuere, vehicula iste dolore, doloremque vestibulum, reprehen derit.",
    color: "#C04700",
    icon: "/images/about-value.png"
  },

];

const About = () => {
  return (
    <Section
      id="About"
      height="auto"
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <Stack 
              justifyContent="center"
              // border="1px red solid"
              height="100%"
            >
              <Typography variant="h5">INTRODUCTION</Typography>
              <Typography variant="h2">How we became </Typography>
              <Typography variant="h2" mb={2}>
                Quality Medical Aid providers
              </Typography>
              <Typography variant="p">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, 
                nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus 
                a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt 
                auctor a ornare odio. Sed non mauris vitae erat consequat. 
              </Typography>
              <Typography variant="p">
                Donec velit. Mauris massa. Vestibulum non nulla. Nam suscipit arcu nec elit. 
                Phasellus sollicitudin iaculis ante. Ut non mauris et sapien tincidunt adipiscing.
                Vestibulum vitae leo. Suspendisse nec mi tristique nulla laoreet vulputate.
              </Typography>

              <Box width={180} mr={3}>
                <LargeButton
                  fullWidth
                  component="a"
                  variant="contained"
                  href="/register"
                >
                  Subscribe
                </LargeButton>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box width={{xs:300, md:"100%"}}>
              <img alt="Quality Medical Aid" src="/images/girl.jpg" width="100%"/>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>  
            <Box mt={6} />
            
            <Grid container spacing={3}>
              {company.map(({title, desc, color, icon}, i) => (
                <Grid key={i} item xs={12} sm={4}>
                  <Box
                    backgroundColor={color}
                    color="white"
                    p={3}
                    textAlign="center"
                  >
                    <Box p={3}>
                      <img alt={title} src={icon} width={64} />
                    </Box>
                    <Typography variant="h3">{title}</Typography>
                    <Typography>{desc}</Typography>
                  </Box>
                </Grid>  
              ))}

            </Grid>

          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default About;