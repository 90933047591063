import { TextField as TextFieldBase} from '@mui/material';
import { styled } from '@mui/material/styles';

const TextField = styled(TextFieldBase)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.primary.main
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    // padding: 3,
    color: theme.palette.primary.main,
    '& fieldset': {
      // border: "none",
      borderWidth: 1,
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.main,
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'green',
      borderWidth: 2,
    },
  },
}));


export default TextField;