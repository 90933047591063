import React from "react";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import theme from "src/theme";

import { DispatchContext, StateContext } from 'src/contexts';
import { useGlobalState } from 'src/hooks';

import Routes from "./routes";

function App(props) {
  const [state, dispatch] = useGlobalState();
  
  React.useEffect(() => props.hideLoader(), [props]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <ThemeProvider theme={theme}>
          {/* <GlobalStyles /> */}
          <Routes />
        </ThemeProvider>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}

export default App;
