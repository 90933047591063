import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  // IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Grid,
} from '@mui/material';

// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) =>({
//   root: {
//     margin: 0,
//     padding: 0,
//     "& > *": {
//       padding: 0
//     },
//   },
//   list: {
//     margin: 0,
//     width: '100%',
//     "& > li": {
//       borderBottom: "1px rgba(0, 0, 0, 0.12) solid",
//     },
//     "& > li:last-child": {
//       borderBottom: "none",
//     },
//   },
//   item: {
//     display: 'flex',
//     flexDirection: 'column'
//   },
//   title: {
//     marginTop: 21,
//     marginBottom: 12,
//     color: theme.palette.primary.main
//   },
// }));


// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  // const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  
  const notificationArr = [
    {label: "Email", field: "email", value: true},
    {label: "Push Notification", field: "push", value: false},
    {label: "Text Messages", field: "sms", value: true},
    {label: "Phone Calls", field: "phone", value: true}
  ];

  const messagesArr = [
    {label: "Email", field: "email", value: true},
    {label: "Push Notification", field: "push", value: false},
    {label: "Text Messages", field: "sms", value: true},
    {label: "Phone Calls", field: "phone", value: false},
  ];


  const handleSubmit = () => {
    // setSubmitting(true);

    // const data = {
    //   notifications: notifications,
    //   messages: messages,
    // };

    // post("/user/settings", data).then((response) => {
    //   const result = response.data;
      
    //   if (result.success) {
        
    //   }
    //   else {
    //   }
    // })
    // .catch(error => {
    //   console.log(error);
    // })
    // .finally(() => {
    //   setSubmitting(false); 
    // });
    
  };

  return (
    <Box>
      <Typography variant="h1">Account Settings</Typography>
      <Typography variant="h5" mb={2}>Configure how we connect with you. (Your can update this later)</Typography>

      <form> 
        
        <Box height="100%" pt={5}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography>
                Notifications
              </Typography>


              <Card>
                <List>
                  {notificationArr.map((item, i) => {
                    return (
                      <ListItem
                        key={i} 
                        alignItems="flex-start"
                      >
                        <ListItemText
                          secondary={item.label}                
                        />
                        <ListItemSecondaryAction>
                          <Box>
                            <Checkbox 
                              checked={item.value}
                              // onChange={ (e) => dispatch({type:"settings", group: "notifications", field: item.field, value: e.target.checked}) }
                            />
                          </Box>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })}
                </List>
              </Card>
            </Grid>
          
            <Grid item xs={12} md={6}>
              <Typography>
                Messages
              </Typography>


              <Card>
                <List>
                  {messagesArr.map((item, i) => {
                    return (
                      <ListItem
                        key={i} 
                        alignItems="flex-start"
                      >
                        <ListItemText
                          secondary={item.label}                
                        />
                        <ListItemSecondaryAction>
                          <Box>
                            <Checkbox 
                              checked={item.value}
                              // onChange={ (e) => dispatch({type:"settings", group: "messages", field: item.field, value: e.target.checked}) }
                            />
                          </Box>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })}
                </List>
              </Card>
            </Grid>
          </Grid>
        </Box>



{/* 
        <Box
          display="flex"
          justifyContent="center"
          mt={5}
          mb={5}
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={submitting}
            onClick={() => handleSubmit()}
          >
            {submitting ? "Updating ..." : "Update Your Settings"}
          </Button>
        </Box> */}
      </form>

      <Box p={5} />
    </Box>
  );
};