import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from "@mui/material";

import LogoMenu from './LogoMenu.js';
import Menu from './Menu';

const topBarHeight = 80;

const TopBar = ({ className, backgroundColor, onMobileNavOpen, ...rest }) => {
  return (
    <Stack>
      <LogoMenu 
        onMobileNavOpen={onMobileNavOpen} 
      />

      <Menu 
        height={topBarHeight} 
      />
    </Stack>
  ); 
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
