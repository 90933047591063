import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6} md={3}>
          <Box>
            <Typography variant="body2">Beneficiaries</Typography>
            <Typography variant="h1">5</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} md={3}>
          <Box>
            <Typography variant="body2">Total Claims</Typography>
            <Typography variant="h1">20</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} md={3}>
          <Box>
            <Typography variant="body2">Subscription</Typography>
            <Typography variant="h1">$23.45</Typography>
          </Box>
        </Grid>

        <Grid item xs={6} md={3}>
          <Box>
            <Typography variant="body2">Arrears</Typography>
            <Typography variant="h1">$0.00</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};