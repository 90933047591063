import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Box 
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pb={4}
    >
      <Typography variant="h3">Members</Typography>
      <Box>
        <IconButton>
          <AddIcon />
        </IconButton>
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};