import React from 'react';
import { Box } from '@mui/material';
import { Page } from 'src/components';

import Banner from "./Banner";
import About from './About';
import Services from './Services';
import RequestForm from './RequestForm';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Page 
      title="Services"
    >
      <Banner />
      <About />
      {/* <CoreServices /> */}
      <Services />
      <Box p={5} />
      <RequestForm />
    </Page>
  );
}
