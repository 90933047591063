import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
// import DirectionsIcon from '@mui/icons-material/Directions';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <Paper
      component="form"
      sx={{ display: 'flex', alignItems: 'center', width: "100%"  }}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Box width="100%" height="100%">
        <InputBase
          sx={{ flex: 1, padding: "10px 20px", margin: 0, width: "100%" }}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
        />
      </Box>
      <IconButton type="submit" color="primary" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton href="#" color="primary" sx={{ p: '10px' }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
    </Paper>
  );
};