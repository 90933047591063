import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import DeleteIcon from '@mui/icons-material/Delete';

import { Section } from 'src/components';

import ToolBar from './ToolBar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(id, avatar, name, surname, role, online, createdAt) {
  return { id, avatar, name, surname, role, online, createdAt };
}

const rows = [

  createData(1, "/images/person-1.jpg", "Arush", "Janji", "Admin", 1, "23/08/2022"),
  createData(2, "/images/person-2.jpg", "Abigail", "Haggle", "Operations", 1, "23/08/2022"),
  createData(3, "/images/person-3.jpg", "Tendai", "Goremusandu", "Finance", 1, "23/08/2022"),
  createData(4, "/images/person-4.jpg", "Timothy", "James", "System", 1, "23/08/2022"),
  createData(5, "/images/person-5.jpg", "Sarah", "Hove", "Marketing", 1, "23/08/2022"),
  createData(6, "/images/person-6.jpg", "Tony", "Kurima", "Admin", 1, "23/08/2022"),
  createData(7, "/images/person-7.jpg", "James", "Masango", "Finance", 1, "23/08/2022"),
  createData(8, "/images/person-8.jpg", "Gregory", "Konnel", "Legal", 1, "23/08/2022"),
];

const colors = {
  Basic: "#006801",
  Standard: "#306597",
  Pro: "#7A3091"
};

export default function CustomizedTables() {
  return (
    <Section
      p={{xs:3, md:5}}
      justifyContent="flex-start"
      height="auto"
    >
      <ToolBar />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>role</StyledTableCell>
              <StyledTableCell align="center">online</StyledTableCell>
              <StyledTableCell>Created</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  <Box display="flex" alignItems={"center"}>
                    <Avatar
                      src={r.avatar}
                      sx={{mr:1}}
                    />
                    {r.name} {r.surname}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{r.role}</StyledTableCell>
                <StyledTableCell align="center">{r.online}</StyledTableCell>
                <StyledTableCell align="center">{r.createdAt}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
}
